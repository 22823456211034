<template>
    <div v-if="create">
        <b-form @submit="onSubmit" v-if="show">
            <b-form-group
                id="input-group-name"
                label="Name:"
                label-for="name"
                description="Beschreibender Name der Liste.">
                <b-form-input
                    id="name"
                    v-model="form.name"
                    type="text"
                    :placeholder="`Liste ${this.customlists.length+1}`">
                </b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-type"
                label="Typ:"
                label-for="type"
                description="Listentyp.">
                <b-form-select id="type" v-model="form.type" :options="options.type" />
            </b-form-group>

            <b-form-row class="mt-2">
                <b-col cols="12">
                    <b-form-checkbox
                        id="enabled"
                        v-model="form.enabled"
                        name="enabled"
                        value="true">
                        Aktiviert
                    </b-form-checkbox>
                </b-col>
            </b-form-row>

            <hr />
            
            <b-button type="submit" variant="primary">Speichern</b-button>
            <b-button type="reset" variant="default" :to="'/tenants/edit/customlists/' + $route.params.id">Abbrechen</b-button>
        </b-form>
    </div>
    <div v-else>
        <div v-if="edit">
            <b-form @submit="onSubmit" v-if="show">
                <b-form-group
                    id="input-group-name"
                    label="Name:"
                    label-for="name"
                    description="Beschreibender Name der Liste.">
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-type"
                    label="Typ:"
                    label-for="type"
                    description="Listentyp.">
                    <b-form-select id="type" v-model="form.type" :options="options.type" />
                </b-form-group>

                <b-form-row class="mt-2">
                    <b-col cols="12">
                        <b-form-checkbox
                            id="enabled"
                            v-model="form.enabled"
                            name="enabled"
                            value="true">
                            Aktiviert
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>

                <hr />
                
                <b-button type="submit" variant="primary">Speichern</b-button>
                <b-button type="reset" variant="default" :to="'/tenants/edit/customlists/' + $route.params.id + '/' + this.list">Abbrechen</b-button>
            </b-form>
        </div>
        <div v-else-if="list">
            <h4>Listeneinstellungen</h4>
            <div class="settings">
                <div>
                    <b>Name:</b> {{this.form.name}}
                </div>
                <div>
                    <b>Typ:</b> <span :class="form.type">{{displayType(this.form.type)}}</span>
                </div>
                <div class="edit">
                    <div><b>Aktiviert:</b> <b-icon icon="check" v-if="this.form.enabled"></b-icon></div>
                    <b-button size="sm" :to="'/tenants/edit/customlists/' + $route.params.id + '/' + this.list + '?edit=true'" title="Bearbeiten" class="mr-1 ml-3">
                        <b-icon icon="pencil" />
                    </b-button>
                </div>
            </div>

            <hr />

            <h4>Einträge</h4>

            <b-table striped hover small primary-key="id" :items="entries.items" :fields="entries.fields">
                <template #head(selected)>
                    <b-form-checkbox id="selected" v-model="allSelected" @change="toggleSelectAll">
                    </b-form-checkbox>
                </template>
                <template #head(actions)>
                    <div class="actions">
                        <b-button size="sm" @click="newEntry()" title="Neuen Eintrag erstellen" class="mr-1" :disabled="entries.items.some(x => !x.id)">
                            <b-icon icon="plus" />
                        </b-button>
                        <b-button size="sm" @click="removeSelectedEntries()" title="Ausgewählte Einträge löschen" class="mr-1" :disabled="selectedEntries.length === 0">
                            <b-icon icon="trash" />
                        </b-button>
                    </div>
                </template>
                <template #cell(selected)="row">
                    <b-form-checkbox :id="'selected-' + row.item.id" v-model="row.item.selected" v-if="row.item.id">
                    </b-form-checkbox>
                </template>
                <template #cell(email)="row">
                    <div v-if="row.item.id">{{row.item.email}}</div>
                    <b-form-group
                        id="input-group-email"
                        label-for="email"
                        :description="`E-Mail für ${displayType(form.type)}ing.`" v-else>
                        <b-form-input
                            id="email"
                            v-model="entries.form.email" 
                            type="email">
                        </b-form-input>
                    </b-form-group>
                </template>
                <template #cell(url)="row">
                    <div v-if="row.item.id">{{row.item.url}}</div>
                    <b-form-group
                        id="input-group-url"
                        label-for="url"
                        :description="`Url für ${displayType(form.type)}ing.`" v-else>
                        <b-form-input
                            id="url"
                            v-model="entries.form.url" 
                            type="text">
                        </b-form-input>
                    </b-form-group>
                </template>
                <template #cell(text)="row">
                    <div v-if="row.item.id">{{row.item.text}}</div>
                    <b-form-group
                        id="input-group-text"
                        label-for="text"
                        :description="`Inhalt für ${displayType(form.type)}ing.`" v-else>
                        <b-form-input
                            id="text"
                            v-model="entries.form.text" 
                            type="text">
                        </b-form-input>
                    </b-form-group>
                </template>
                <template #cell(actions)="row">
                    <div class="actions">
                        <b-button size="sm" title="Speichern" @click="saveEntry(row.item)" :disabled="!entries.form.email && !entries.form.url && !entries.form.text" class="mr-1" v-if="isAdmin && !row.item.id">
                            <b-icon icon="check" /> 
                        </b-button>
                        <b-button size="sm" title="Löschen" @click="removeEntry(row.item)" class="mr-1" v-if="isAdmin">
                            <b-icon icon="trash" v-if="row.item.id" />
                            <b-icon icon="x" v-else />
                        </b-button>
                    </div>
                </template>
                <template #row-details>
                    <div><small>Erfassen Sie entweder eine E-Mail-Adresse (Sender-E-Mail), eine Url (Links in der E-Mail) oder Inhalt/Wörter (Inhalt der E-Mail). Für die Werte sind Regex-Ausdrücke möglich.</small></div>
                </template>
            </b-table>
        </div>
        <div v-if="!list">
            <b-button size="sm" class="mr-1 create" :to="'/tenants/edit/customlists/' + $route.params.id + '?create=true'" v-if="isAdmin">
                <b-icon icon="file-plus" /> <span class="d-none d-sm-inline">Neue Liste</span>
            </b-button>
            <b-table ref="customlists" striped hover primary-key="id" :items="customlists" :fields="fields" @row-clicked="details">
                <template #head(selected)>
                    <b-form-checkbox id="selected" v-model="allSelected" @change="toggleSelectAll">
                    </b-form-checkbox>
                </template>
                <template #head(actions)>
                    <div class="actions">
                        <b-button size="sm" @click="removeSelected()" title="Ausgewählte Listen löschen" class="mr-1" :disabled="selected.length === 0">
                            <b-icon icon="trash" />
                        </b-button>
                    </div>
                </template>
                <template #cell(selected)="row">
                    <b-form-checkbox :id="'selected-' + row.item.id" v-model="row.item.selected">
                    </b-form-checkbox>
                </template>
                <template #cell(type)="row">
                    <div class="type">
                        {{displayType(row.item.type)}}
                    </div>
                </template>
                <template #cell(enabled)="row">
                    <div class="enabled">
                        <b-icon icon="check" v-if="row.item.enabled" />
                    </div>
                </template>
                <template #cell(actions)="row">
                    <div class="actions">
                        <b-button size="sm" @click="remove(row.item)" class="mr-1" v-if="isAdmin">
                            <b-icon icon="trash" /> <span class="d-none d-sm-inline">Löschen</span>
                        </b-button>
                    </div>
                </template>
            </b-table>
        </div>
    </div>
</template>

<style scoped>
    .create {
        float: right;
        margin-bottom: 20px;
    }
    .actions {
        text-align: right;
    }
    .off {
        display: none !important;
    }
    button.add {
        margin-top: 10px;
    }
    .edit, .settings {
        display: flex;
        justify-content: space-between;
    }
    .whitelist {
        color: green;
    }
    .blacklist {
        color: red;
    }
</style>

<script>
import _ from 'lodash';
import config from '../config';

export default {
  name: 'Recipients',
  props: ['id', 'list'],
  data() {
    return {
        fields: [{
            key: 'selected',
            label: ''
        }, {
            key: 'name',
            label: 'Name',
            sortable: true
        }, {
            key: 'type',
            label: 'Typ'
        }, {
            key: 'enabled',
            label: 'Aktiviert'
        }, {
            key: 'actions', label: ''
        }],
        customlists: [],
        form: {
            name: null,
            type: 'blacklist',
            enabled: true
        },
        options: {
            type: [{
                value: 'blacklist',
                text: this.displayType('blacklist')
             }, {
                value: 'whitelist',
                text: this.displayType('whitelist')
             }]
        },
        privileges: {},
        changed: false,
        show: true,
        allSelected: false,
        entries: {
            fields: [{
                key: 'selected',
                label: ''
            }, {
                key: 'email',
                label: 'E-Mail'
            }, {
                key: 'url',
                label: 'Url'
            }, {
                key: 'text',
                label: 'Inhalt'
            }, {
                key: 'actions', label: ''
            }],
            form: {
                email: null,
                url: null,
                text: null
            },
            items: []
        }
    }
  },

  computed: {
      create() {
          return !!_.get(this.$route, 'query.create');
      },
      edit() {
          return !!this.list && !!_.get(this.$route, 'query.edit');
      },
      isAdmin() {
        return !!_.get(this.privileges, 'administrator')
      },
      selected() {
          return this.customlists.filter(x => !!x.selected);
      },
      selectedEntries() {
          return this.entries.items.filter(x => !!x.selected);
      }
  },

  watch: {
      form: {
        handler() {
            this.changed = true;
        },
        deep: true
      },
      list() {
          this.reload();
      }
  },

  methods: {
    reset() {
        Object.assign(this.$data, this.$options.data.apply(this));
    },
    reload() {
      this.reset();
      if(this.id && this.list) {
        fetch(config.apiUrl('/customlist/' + this.id + '/' + this.list), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.form = _.omit(data, 'privileges');
            this.privileges = data.privileges || {};
            this.allSelected = false;
        }).then(() => {
            this.customlistEntries();
        });
      }
      else if(this.id) {
        fetch(config.apiUrl('/customlist/' + this.id), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.customlists = data.items.map((x) => {
                x.selected = false;
                return x;
            });
            this.privileges = data.privileges || {};
            this.allSelected = false;
          });
      }
    },

    displayType(val) {
        if(val === 'blacklist')
            return 'Blacklist';
        else if(val === 'whitelist')
            return 'Whitelist';
        else
            return val;
    },

    details(item) {
        this.$router.push('/tenants/edit/customlists/' + this.id + '/' + item.id);
    },

    remove(item) {
        this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
            if(value) {
                fetch(config.apiUrl('/customlist/' + this.id + '/' + item.id), {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => config.checkResponse(this, response)).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    removeSelected() {
        let _selected = _.clone(this.selected);
        this.$bvModal.msgBoxConfirm(`${_selected.length} Listen werden gelöscht. Sind Sie sicher?`).then(value => {
            if(value) {
                return _selected.reduce((p, item) => {
                    return p.then(() => {
                        return fetch(config.apiUrl('/customlist/' + this.id + '/' + item.id), {
                            method: 'DELETE',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).catch(() => {});
                    });
                }, Promise.resolve()).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    toggleSelectAll(checked) {
        this.customlists.forEach(x => {
            x.selected = !!checked;
        });
        this.entries.items.forEach(x => {
            x.selected = !!checked;
        });
    },

    onSubmit(evt) {
      evt.preventDefault()

      let data = _.assign({
          tenant: this.id
      }, this.form);
      Object.keys(data).forEach((x) => {
        if(data[x] === '')
            data[x] = null;
        if(['true', 'false'].indexOf(data[x]) !== -1)
            data[x] = data[x] === 'true';
      });

      let url = config.apiUrl('/customlist');
      if(this.list)
        url += '/' + this.id + '/' + this.list;
    
      fetch(url, {
        method: this.list ? 'PUT' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => config.checkResponse(this, response)).then(() => {
        this.$router.push('/tenants/edit/customlists/' + this.id);
        this.$nextTick(() => {
            this.reload()
        });
      });
    },

    customlistEntries() {
        return fetch(config.apiUrl('/customlist/' + this.id + '/' + this.list + '/entry'), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.entries.items = data.items.map((x) => {
                x.selected = false;
                return x;
            });
            this.allSelected = false;
            return this.entries.items;
          });
    },

    newEntry() {
        this.entries.items.unshift({
            _showDetails: true
        });
    },

    saveEntry(item) {
        let url = config.apiUrl('/customlist/entry');
        if(item.id)
            url += '/' + item.id;

        let data = {
            tenant: this.id,
            list: this.list
        };
        ['text', 'email', 'url'].forEach(x => {
            if(this.entries.form[x])
                data[x] = this.entries.form[x];
        });

        return fetch(url, {
          method: item.id ? 'PUT' : 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).
          then(response => config.checkResponse(this, response)).
          then(() => {
            this.reload();
          });
    },

    removeEntry(item) {
        if(!item.id) {
            this.entries.items.shift();
        } else {
            this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
                if(value) {
                    fetch(config.apiUrl('/customlist/' + this.id + '/' + this.list + '/entry/' + item.id), {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then(response => config.checkResponse(this, response)).then(() => {
                        setTimeout(() => this.reload());
                    });
                }
            }).catch(err => {
                console.log(err);
                setTimeout(() => this.reload());
            });
        }
    },

    removeSelectedEntries() {
        let _selected = _.clone(this.selectedEntries);
        this.$bvModal.msgBoxConfirm(`${_selected.length} Einträge werden gelöscht. Sind Sie sicher?`).then(value => {
            if(value) {
                return _selected.reduce((p, item) => {
                    return p.then(() => {
                        return fetch(config.apiUrl('/customlist/' + this.id + '/' + this.list + '/entry/' + item.id), {
                            method: 'DELETE',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).catch(() => {});
                    });
                }, Promise.resolve()).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    }
  },

  mounted() {
    this.reload();
  }
}
</script>
