<template>
  <div id="app">
    <Navbar :profile="profile" :loaded="loaded"/>
    <div id="page">
      <router-view :profile="profile" :loaded="loaded"/>
    </div>
  </div>
</template>

<style>
#app {
  font-family: 'PT Sans', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.page {
  margin: 20px;
}
</style>
<script>
import _ from 'lodash';
import config from './config';
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'

// no need to login for this routes
const publicRoutes = ['/report', '/guard', '/about', '/info'];

export default {
  components: {
    Navbar
  },

  data() {
    return {
      profile: null,
      loaded: false
    };
  },

  mounted() {
    let publicPath = config.getAppRoot();
    if(_.some(publicRoutes, (x) => {
      let route = (publicPath || '') + x;
      route = route.replace(/\/{2,}/g, '/');
      return window.location.pathname.indexOf(route) === 0;
    })) {
      return;
    }

    fetch(config.apiUrl('/profile'), {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
    }).
      then(response => config.checkResponse(this, response)).
      then(data => {
          if(data && data.id)
            this.profile = data;
          else
            this.profile = null;
          
          this.$emit('profile', this.profile);
          this.loaded = true;

          this.$root.$on('login', () => {
            this.profile = null;
            this.loaded = false;
            this.$router.push('/');

            setTimeout(() => {
              this.loaded = true;
            }, 100);
          });

          setTimeout(() => {
            if(data && data.statusCode === 401) {
              let publicPath = config.getAppRoot();
              if(!publicPath || publicPath.indexOf(window.location.pathname) !== 0) {
                window.sessionStorage.setItem('route', window.location.pathname);
                this.$router.push('/');
              }
            } else if(this.profile) {
              let route = window.sessionStorage.getItem('route');
              if(route) {
                window.sessionStorage.removeItem('route');
                if(route !== window.location.pathname) {
                  let publicPath = config.getAppRoot();
                  if(publicPath) {
                    if(publicPath[publicPath.length-1] === '/')
                      publicPath = publicPath.substr(0, publicPath.length-1);
                    let re = new RegExp('^' + publicPath.replace(/\//g, '\\/'));
                    route = route.replace(re, '');
                  }
                  this.$router.push(route);
                }
              }
            } else {
              window.sessionStorage.removeItem('route');
            }
          });
      }).
      catch(() => {
        this.profile = null;
        this.loaded = true;
      });
  }
}
</script>