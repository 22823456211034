let backend = '';
if(('webpackHotUpdate' in window) || ('webpackHotUpdatefrontend' in window))
    backend = 'https://127.0.0.1:9082';

export default {
    apiUrl(url) {
        if(url[0] !== '/')
            url = '/' + url;

        if(url.indexOf('/api/v1') === 0)
            return backend + url;
        else
            return backend + '/api/v1' + url;
    },

    getAppRoot() {
        let elems = document.getElementsByName('approot');
        for(let n = 0; n < elems.length; ++n) {
          if(elems[n].nodeName.toLowerCase() === 'meta')
            return elems[n].getAttribute('content');
        }
        return null;
    },      

    checkResponse(app, response) {
        if(!response.ok) {
            if(response.status === 401) {
                setTimeout(() => {
                    let publicPath = this.getAppRoot();
                    if(!publicPath || publicPath.indexOf(window.location.pathname) !== 0)
                        window.sessionStorage.setItem('route', window.location.pathname);
                    app.$root.$emit('login');
                });
            }

            throw new Error(response.statusText);
        }

        return response.json().catch(() => null);
    },

    // cf. backend/lib/mail.js
    mailStatus: {
        0: 'Eingeliefert',
        1: 'In Prüfung',
        2: 'Geprüft -\nnicht eindeutig',
        512: 'Geprüft -\nUnverdächtig',
        1024: 'Geprüft -\nSpam',
        2048: 'Geprüft -\nPhishing',
        65535: 'Fehler bei\nder Prüfung'
    },

    statusColor: {
        2: '#C9CBCF',
        512: 'green',
        1024: 'yellow',
        2048: '#bd0543'
    }
};