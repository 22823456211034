<template>
    <div class="internal-confirmation">
        <div class="info" v-if="report">
          <h2>{{subject}}</h2>
          <div class="flex-with-btn">
            <div><strong>Absender:</strong> {{from}}</div>
            <div>
              <b-button type="button" size="sm" variant="success" @click="onAddToCustomList" data-list="whitelist" :disabled="!customList" title="auf Whitelist setzen">
                <b-icon icon="shield-check" />
              </b-button>
              <b-button type="button" size="sm" variant="danger" @click="onAddToCustomList" data-list="blacklist" :disabled="!customList" title="auf Blacklist setzen">
                <b-icon icon="shield-exclamation" />
              </b-button>
            </div>
          </div>
          <div><strong>Empfänger:</strong> {{to}}</div>
          <div><strong>Datum:</strong> {{displayDate(created)}}</div>
        </div>

        <b-card title="Gesamtergebnis">
          <ReportTrafficLights :report="report" />
        </b-card>

        <b-card title="Auswertungen">
          <b-table :items="checks" :fields="categories">
              <template #cell()="row">
                  <span>{{row.value.score}}{{reliability(row.value) ? ' (' + reliability(row.value) + '%)' : ''}}</span>
              </template>
          </b-table>
          <div class="actions">
              <b-button type="button" variant="success" @click="onNormal">Unverdächtig</b-button>
              <div>
                  <a :href="publicReport" target="_blank">Öffentlicher Report</a>
              </div>
              <b-button type="button" variant="warning" @click="onSpam">SPAM</b-button>
              <b-button type="button" variant="danger" @click="onPhish">PHISH</b-button>
          </div>
        </b-card>

        <div class="screenshot" v-if="!html">
          <div class="loading" v-if="!loaded">
            <div>
              <b-spinner label="Spinning"></b-spinner>
            </div>
            <div>
              Lade Screenshot ...
            </div>
          </div>
          <img border="0" :src="screenshot" @load="onScreenshotLoaded" @error="onScreenshotFailed" />
          <b-button class="load-html" type="button" variant="warning" @click="loadHtml">Mail als HTML anzeigen</b-button>
        </div>
        <div class="html" v-else>
          <h3>ACHTUNG: HTML-Ansicht der Mail. Alle Links sind aktiv!</h3>
          <iframe id="iframe" border="0" :src="html" @load="onLoaded"></iframe>
        </div>
    </div>
</template>

<style scoped lang="scss">
.screenshot {
  margin-top: 20px;
  text-align: center;
}
.loading > div {
  margin-bottom: 1em;
}
img {
  width: 100%;
  height: auto;
}
.load-html {
  margin-top: 20px;
}
.html {
  margin-top: 20px;
}
.html > h3 {
  color: #ff0000;
}
iframe {
    border: none;
    width: 100%;
    overflow-y: visible;
}
.actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
}
.info, .card {
  margin-bottom: 20px;
}
.flex-with-btn {
  display: flex;
  justify-content: space-between;
  align-items: end;

  .btn {
    margin-left: 10px;
  }
}
</style>

<script>
import _ from 'lodash';
import config from '../config';
import utils from '../utils';
import ReportAdapter from './ReportAdapter.js';
import ReportTrafficLights from './reports/ReportTrafficLights.vue';

export default {
  name: 'InternalConfirmation',
  props: ['id'],

  data() {
    return {
        confirmation: null,
        report: null,
        html: null,
        loaded: false,
        customList: true
    }
  },

  computed: {
    categories() {
        let categories = ReportAdapter.categories(this.report);
        return categories.map(x => {
            return {
                key: x,
                label: ReportAdapter.title(x)
            };
        });
    },

    checks() {
        if(!this.report)
            return [];
        
        let categories = ReportAdapter.categories(this.report);
        let checks = {};
        let self = this;
        categories.forEach(x => {
            if(x === 'result') {
                checks[x] = _.pick(self.report.result, 'status', 'score', 'points', 'reliability');
            } else {
                let check = _.find(self.report.checks, {type: x});
                if(check)
                    checks[x] = _.pick(check.result, 'status', 'score', 'points', 'reliability');
            }
        });
        return [checks];
    },

    subject() {
      return _.get(this, 'report.mail.info.subject') || '';
    },

    from() {
      return _.get(this, 'report.mail.info.from', [_.get(this, 'report.sender', '')]).join(', ');
    },

    to() {
      return _.get(this, 'report.mail.info.to', [_.get(this, 'report.recipient', '')]).join(', ');
    },

    created() {
      return _.get(this, 'report.mail.created', null);
    },

    screenshot() {
        let url = _.get(this, 'report.mail.screenshot');
        return url ? config.apiUrl(url) : url;
    },

    publicReport() {
        return this.report ? '/frontend/report/' + (this.$route.params.tenant || this.$route.params.id) + '/' + this.report.id : null;
    }
  },

  watch: {
    confirmation() {
      this.$emit('changed', this.confirmation);

      if(this.confirmation.report) {
        let url = config.apiUrl('/report/' + (this.$route.params.tenant || this.$route.params.id) + '/' + this.confirmation.report);
        fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.report = data;
          });
      }
    }
  },

  methods: {
    reload() {
      if(this.id) {
        fetch(config.apiUrl('/confirmation/' + (this.$route.params.tenant || this.$route.params.id) + '/' + this.id), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.confirmation = data;
          });
      }
    },

    loadHtml() {
        let html = _.get(this, 'report.mail.parsed.html') || _.get(this, 'report.mail.parsed.textAsHtml') || _.get(this, 'report.mail.parsed.textAsHtml') || _.get(this, 'report.mail.parsed.textAs');
        if(html) {
            let blob = new Blob([html], {type: 'text/html'});
            this.html = URL.createObjectURL(blob);
        } else {
            this.html = 'about:blank';
        }
    },

    onLoaded() {
        var iframe = document.getElementById('iframe');
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';

        let url = iframe.src;
        if(url && url !== 'about:blank')
            URL.revokeObjectURL(url);
    },

    onScreenshotLoaded: function() {
      this.loaded = true;
    },

    onScreenshotFailed: function() {
      this.loadHtml();
    },

    updateStatus(hint) {
      let tenant = this.$route.params.tenant || this.$route.params.id;
      fetch(config.apiUrl('/confirmation/' + tenant + '/' + this.id), {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          hint: hint
        })
      }).
        then(response => config.checkResponse(this, response)).
        then(() => {
          this.$emit('reload');
          setTimeout(() => {
              this.$router.push('/tenants/edit/confirmations/' + tenant);
          });
        });
    },
    onNormal(ev) {
        ev.preventDefault();

        // TODO: named status
        this.updateStatus('normal');
    },

    onSpam(ev) {
        ev.preventDefault();

        // TODO: named status
        this.updateStatus('spam');
    },

    onPhish(ev) {
        ev.preventDefault();

        // TODO: named status
        this.updateStatus('phish');
    },

    onAddToCustomList(ev) {
      ev.preventDefault();

      let list = ev.currentTarget.getAttribute('data-list');
      if(list) {
        let tenant = this.$route.params.tenant || this.$route.params.id;
        return utils.addToCustomList(tenant, list, this.from).catch(() => {}).finally(() => {
          this.customList = false;
        });
      }
    },

    displayDate(val) {
        return new Date(val).toLocaleString();
    },

    reliability(item) {
        let val = _.get(item, 'reliability');
        if(_.isNumber(val))
            val = Math.round(val*100);
        return val;
    }
  },

  mounted() {
    this.reload();
  },

  components: {
    ReportTrafficLights
  }
}
</script>
