<template>
    <div class="audittrail">
        <b-table :key="created" id="audittrail" ref="audittrail" outlined primary-key="id" 
            :items="audittrail" 
            :fields="fields" 
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc">
            <template #row-details="row">
                <ul class="details">
                    <li v-for="line in details(row.item)" :key="line.id">
                        {{line.val}}
                    </li>
                </ul>
            </template>
        </b-table>
        <div class="pagination-wrapper">
            <b-form-select v-model="count.value" :options="count.options" />
            <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="audittrail"
                align="center"
            ></b-pagination>
        </div>
    </div>
</template>

<style>
    tr.b-table-has-details {
        cursor: pointer;
    }
    tr.b-table-details small {
        color: #333;
    }
</style>

<style scoped lang="scss">
table {
    a, a:visited, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
    }

    ul.details {
        font-size: small;
    }
}
.pagination-wrapper {
    display: flex;
    justify-content: center;

    select {
        width: 80px;
        margin-right: 20px;
    }
}
</style>

<script>
import _ from 'lodash';
import config from '../config';

export default {
    name: 'AuditTrail',
    props: ['id'],

    data() {
        return {
            fields: [{
                key: 'account',
                label: 'Benutzer',
                sortable: true
            }, {
                key: 'action',
                label: 'Aktion',
                sortable: true
            }, {
                key: 'scope',
                label: 'Bereich',
                sortable: true
            }, {
                key: 'created',
                label: 'Zeitstempel',
                sortable: true,
                formatter: 'displayDate'
            }],
            count: {
                value: 10,
                options: [{
                    value: 10,
                    text: 10
                }, {
                    value: 25,
                    text: 25
                }, {
                    value: 50,
                    text: 50
                }, {
                    value: 100,
                    text: 100
                }]
            },
            currentPage: 1,
            perPage: 10,
            total: 0,
            sortBy: 'created',
            sortDesc: true,
            items: []
        };
    },

    computed: {
        created() {
            return Date.now();
        }
    },

    watch: {
      'count.value'() {
            this.perPage = this.count.value;
        }
    },

    methods: {
        reload() {
            this.$root.$emit('bv::refresh::table', 'audittrail');
        },

        savePaging(ctx) {
            let tenant = (this.$route.params.tenant || this.$route.params.id);
            window.sessionStorage.setItem(`${tenant}|audittrail`, JSON.stringify({
                currentPage: ctx.currentPage,
                perPage: ctx.perPage,
                sortBy: ctx.sortBy,
                sortDesc: ctx.sortDesc,
                total: this.total,
                count: {
                    value: this.count.value
                }
            }));
        },

        audittrail(ctx) {
            let start = (ctx.currentPage-1) * ctx.perPage;
            let url = config.apiUrl('/audittrail') + '?tenant=' + (this.$route.params.tenant || this.$route.params.id);

            return fetch(url + '&start=' + start + '&count=' + ctx.perPage + '&sortfield=' + ctx.sortBy + '&sortdir=' + (ctx.sortDesc ? 'desc' : 'asc'), {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).
            then(response => config.checkResponse(this, response)).
            then(data => {
                this.total = data.total || 0;
                let _items = data.items || [];
                this.items = _items.map((x) => {
                    return _.assign(x, {
                        selected: false,
                        from: _.get(x, 'mail.info.from', [x.sender]).join(', '),
                        to: _.get(x, 'mail.info.to', [x.recipient]).join(', '),
                        _showDetails: true
                    });
                });
                this.allSelected = false;

                // preserve paging postition
                this.savePaging(ctx);

                return this.items;
            }).catch((err) => {
                console.log(err);
            });
        },

        displayDate(val) {
            return new Date(val).toLocaleString();
        },

        details(item) {
            let details = ['URL: ' + _.get(item, 'data.url') || ''];
            if(_.get(item, 'data.info')) {
                details.push('Sender: ' + _.get(item, 'data.info.from', []).join(', '));
                details.push('Empfänger: ' + _.get(item, 'data.info.to', []).join(', '));
                details.push('Betreff: ' + _.get(item, 'data.info.subject') || '');
                if(_.get(item, 'data.info.date'))
                    details.push('Datum: ' + new Date(_.get(item, 'data.info.date').toLocaleString()));
            }

            let idx = 0;
            return details.map(x => {
                return {
                    id: idx++,
                    val: x
                };
            });
        }
    },

    beforeMount() {
        // restore paging context
        let tenant = (this.$route.params.tenant || this.$route.params.id);
        let ctx = window.sessionStorage.getItem(`${tenant}|audittrail`);
        if(ctx) {
            try {
                ctx = JSON.parse(ctx);
                _.merge(this, ctx);
            } catch(e) {
                // empty
            }
        }
    }
}
</script>