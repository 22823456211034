<template>
    <div v-if="mail">
        <InternalMail :id="mail"/>
    </div>
    <div class="imports" v-else>
        <b-table id="imports" ref="imports" outlined primary-key="id" 
            :items="imports" 
            :fields="fields" 
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @row-clicked="gotoDetails">
            <template #cell()="row">
                <b-link :href="details(row.item)">{{row.value}}</b-link>
            </template>
            <template #cell(actions)="row">
                <div class="actions">
                    <b-button size="sm" @click="remove(row.item)" title="Löschen" class="mr-1">
                        <b-icon icon="trash" /> <span class="d-none d-sm-inline">Löschen</span>
                    </b-button>
                </div>
            </template>
            <template #row-details="row">
                <b-link :href="details(row.item)"><small>{{subject(row.item)}}</small></b-link>
            </template>
        </b-table>
        <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="imports"
            align="center"
        ></b-pagination>
    </div>
</template>

<style>
    .actions {
        text-align: right;
        white-space: nowrap;
    }
    tr.b-table-has-details {
        cursor: pointer;
    }
    tr.b-table-details small {
        color: #333;
    }
</style>

<style scoped lang="scss">
table {
    a, a:visited, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
    }
}
</style>

<script>
import _ from 'lodash';
import config from '../config';
import InternalMail from './InternalMail.vue';

export default {
  components: { InternalMail },
  name: 'Imports',
  props: ['id', 'mail'],

  data() {
    return {
        fields: [{
            key: 'sender',
            label: 'Sender',
            sortable: true
        }, {
            key: 'recipient',
            label: 'E-Mail-Adresse',
            sortable: false
        }, {
            key: 'created',
            label: 'Erstellt',
            sortable: true,
            formatter: 'displayDate'
        }, {
            key: 'actions', label: ''
        }],
        currentPage: 1,
        perPage: 10,
        total: 0,
        sortBy: 'created',
        sortDesc: true
    };
  },

  methods: {
    reload() {
        this.$root.$emit('bv::refresh::table', 'imports');
    },

    imports(ctx) {
      let start = (ctx.currentPage-1) * ctx.perPage;
      let url = config.apiUrl('/mail') + '?type=import&tenant=' + (this.$route.params.tenant || this.$route.params.id);

      return fetch(url + '&start=' + start + '&count=' + ctx.perPage + '&sortfield=' + ctx.sortBy + '&sortdir=' + (ctx.sortDesc ? 'desc' : 'asc'), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
        then(response => config.checkResponse(this, response)).
        then(data => {
            this.total = data.total || 0;
            let items = data.items || [];
            return items.map((x) => {
                return _.assign(x, {
                    _showDetails: true
                });
            });
        });
    },

    displayDate(val) {
        return new Date(val).toLocaleString();
    },

    subject(item) {
        return _.get(item, 'info.subject') || '';
    },

    details(item, forRouter) {
        let tenant = this.$route.params.tenant || this.$route.params.id;
        return (forRouter ? '' : '/frontend') + '/tenants/edit/imports/' + tenant + '/' + item.id;
    },

    gotoDetails(item) {
        this.$router.push(this.details(item, true));
    },

    remove(item) {
        this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
            if(value) {
                let tenant = this.$route.params.tenant || this.$route.params.id;
                fetch(config.apiUrl('/mail/' + tenant + '/' + item.id), {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => config.checkResponse(this, response)).then(this.reload);
            }
        }).catch(err => {
            console.log(err);
        });
    }
  }
}
</script>
