<template>
    <div v-if="report">
        <InternalReport :id="report" @reload="reload"/>
    </div>
    <div class="reports" v-else>
        <b-form @submit="onSubmit">
            <b-form-group
                id="input-group-1"
                label="Filter:"
                label-for="input-1"
                description="E-Mail-Status">
                <b-input-group>
                    <b-form-select id="input-1" v-model="status.value" :options="status.options" />
                    <b-input-group-append>
                        <b-button @click="reload" title="Neu laden">
                            <b-icon icon="bootstrap-reboot" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <b-form-group
                id="input-group-2"
                label="Suche:"
                label-for="input-2">
                <b-input-group>
                    <b-form-input id="input-2" v-model="search" debounce="500" />
                    <b-input-group-append>
                        <b-button @click="reload" title="Suchen">
                            <b-icon icon="search" />
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-form>
        <b-table id="reports" ref="reports" outlined primary-key="id"
            :items="reports" 
            :fields="fields" 
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :tbody-tr-class="scoreClass"
            @row-clicked="gotoDetails">
            <template #head(selected)>
                <b-form-checkbox id="selected" v-model="allSelected" @change="toggleSelectAll">
                </b-form-checkbox>
            </template>
            <template #head(actions)>
                <div class="actions">
                    <b-button size="sm" @click="removeSelected()" title="Ausgewählte Auswertungen löschen" class="mr-1" :disabled="selected.length === 0">
                        <b-icon icon="trash" />
                    </b-button>
                </div>
            </template>
            <template #cell()="row">
                <b-link @click="gotoDetails(row.item)">{{row.value}}</b-link>
            </template>
            <template #cell(selected)="row">
                <b-form-checkbox :id="'selected-' + row.item.id" v-model="row.item.selected">
                </b-form-checkbox>
            </template>
            <template #cell(score)="row">
                <b-link @click="gotoDetails(row.item)">{{row.item.result.score}} {{reliability(row.item) ? '(' + reliability(row.item) + '%)' : ''}}</b-link>
            </template>
            <template #cell(actions)="row">
                <div class="actions">
                    <b-button size="sm" @click="refresh(row.item)" title="Refresh" class="mr-1">
                        <b-icon icon="bootstrap-reboot"/>
                    </b-button>
                    <b-button size="sm" :href="download(row.item)" download title="Download" class="mr-1">
                        <b-icon icon="download"/>
                    </b-button>
                    <b-button size="sm" @click="confirmation(row.item)" title="Bestätigung erstellen" class="mr-1">
                        <b-icon icon="check"/>
                    </b-button>
                    <b-button size="sm" @click="remove(row.item)" title="Löschen" class="mr-1">
                        <b-icon icon="trash" />
                    </b-button>
                </div>
            </template>
            <template #row-details="row">
                <b-link @click="gotoDetails(row.item)"><small>{{subject(row.item)}}</small></b-link>
            </template>
        </b-table>
        <div class="pagination-wrapper">
            <b-form-select v-model="count.value" :options="count.options" />
            <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="reports"
                align="center"
            ></b-pagination>
        </div>
    </div>
</template>

<style>
    .actions {
        text-align: right;
        white-space: nowrap;
    }
    tr.phish, tr.error {
        color: red !important;
    }
    tr.normal {
        color: green !important;
    }
    tr.spam, tr.suspicious {
        color: orange !important;
    }
    tr.b-table-has-details {
        cursor: pointer;
    }
    tr.b-table-details small {
        color: #333;
    }
</style>

<style scoped lang="scss">
table {
    a, a:visited, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
    }

    a.btn, a.btn:visited, a.btn:hover, a.btn:focus, a.btn:active {
        color: #fff;
    }
}
.pagination-wrapper {
    display: flex;
    justify-content: center;

    select {
        width: 80px;
        margin-right: 20px;
    }
}
</style>

<script>
import _ from 'lodash';
import config from '../config';
import InternalReport from './InternalReport.vue';

export default {
  components: { InternalReport },
  name: 'Reports',
  props: ['id', 'report'],

  data() {
    return {
        fields: [{
            key: 'selected',
            label: ''
        }, {
            key: 'from',
            label: 'Sender',
            sortable: true
        }, {
            key: 'to',
            label: 'Empfänger',
            sortable: false
        }, {
            key: 'score',
            label: 'Ergebnis',
            sortable: true
        }, {
            key: 'created',
            label: 'Erstellt',
            sortable: true,
            formatter: 'displayDate'
        }, {
            key: 'actions', label: ''
        }],
        status: {
            value: null,
            options: [{
                value: null,
                text: '-'
            }, {
                value: '2',
                text: 'Unsicher'
            }, {
                value: '512',
                text: 'In Ordnung'
            }, {
                value: '1024',
                text: 'Spam'
            }, {
                value: '2048',
                text: 'Phish'
            }, {
                value: '65535',
                text: 'Fehler'
            }]
        },
        count: {
            value: 10,
            options: [{
                value: 10,
                text: 10
            }, {
                value: 25,
                text: 25
            }, {
                value: 50,
                text: 50
            }, {
                value: 100,
                text: 100
            }]
        },
        currentPage: 1,
        perPage: 10,
        total: 0,
        sortBy: 'created',
        sortDesc: true,
        search: null,
        lastMailId: null,
        items: [],
        allSelected: false
    };
  },

  computed: {
      selected() {
          return this.items.filter(x => !!x.selected);
      }
  },

  watch: {
      'status.value'() {
          if(this.currentPage !== 1)
            this.currentPage = 1;
          else
            this.reload();
      },
      'search'() {
          this.lastMailId = null;
          this.reload();
      },
      'count.value'() {
          this.perPage = this.count.value;
      }
  },

  methods: {
    reload() {
        this.$root.$emit('bv::refresh::table', 'reports');
    },

    onSubmit(ev) {
        ev.preventDefault();
    },

    savePaging(ctx) {
        let tenant = (this.$route.params.tenant || this.$route.params.id);
        let _ctx = window.sessionStorage.getItem(`${tenant}|reports`) || '{}';
        try {
            _ctx = JSON.parse(_ctx);
        } catch(e) {
            // empty
            _ctx = {};
        }

        window.sessionStorage.setItem(`${tenant}|reports`, JSON.stringify(_.assign(_ctx, {
            currentPage: ctx.currentPage,
            perPage: ctx.perPage,
            sortBy: ctx.sortBy,
            sortDesc: ctx.sortDesc,
            total: this.total,
            search: this.search,
            lastMailId: this.lastMailId,
            status: {
                value: this.status.value
            },
            count: {
                value: this.count.value
            }
        })));
    },

    reports(ctx) {
      if(!_.isEmpty(this.search))
        return this.searchReports(ctx);
    
      let tenant = (this.$route.params.tenant || this.$route.params.id);
      let start = (ctx.currentPage-1) * ctx.perPage;
      let url = config.apiUrl('/report') + '?use=check&tenant=' + tenant;
      if(this.status.value)
        url += '&status=' + this.status.value;

      return fetch(url + '&start=' + start + '&count=' + ctx.perPage + '&sortfield=' + ctx.sortBy + '&sortdir=' + (ctx.sortDesc ? 'desc' : 'asc'), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
        then(response => config.checkResponse(this, response)).
        then(data => {
            this.total = data.total || 0;
            let _items = data.items || [];
            this.items = _items.map((x) => {
                return _.assign(x, {
                    selected: false,
                    from: _.get(x, 'mail.info.from', [x.sender]).join(', '),
                    to: _.get(x, 'mail.info.to', [x.recipient]).join(', '),
                    _showDetails: true
                });
            });
            this.allSelected = false;

            // preserve paging postition
            this.savePaging(ctx);

            return this.items;
        }).catch((err) => {
            console.log(err);
        });
    },

    searchReports(ctx) {
      let url = config.apiUrl('/search/reports') + '?q=' + encodeURIComponent(this.search) + '&tenant=' + (this.$route.params.tenant || this.$route.params.id) + '&count=' + ctx.perPage;
      if(this.lastMailId && ctx.currentPage > 1)
        url += '&after=' + this.lastMailId;
    
      return fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }).
      then(response => config.checkResponse(this, response)).
      then(data => {
        this.total = data.total || 0;
        let _items = data.items || [];
        this.items = _items.map((x) => {
            return _.assign(x, {
                from: _.get(x, 'mail.info.from', [x.sender]).join(', '),
                to: _.get(x, 'mail.info.to', [x.recipient]).join(', '),
                _showDetails: true
            });
        });
        if(this.items.length > 0)
            this.lastMailId = _.last(this.items).mail.id;
        this.allSelected = false;

        // preserve paging postition
        this.savePaging(ctx);

        return this.items;
      }).catch((err) => {
        console.log(err);
      });
    },

    displayDate(val) {
        return new Date(val).toLocaleString();
    },

    subject(item) {
        return _.get(item, 'mail.info.subject') || '';
    },

    reliability(item) {
        let val = _.get(item, 'result.reliability');
        if(_.isNumber(val))
            val = Math.round(val*100);
        return val;
    },

    scoreClass(item) {
      if(_.get(item, 'result.phish') === true)
        return 'phish';
      else if(_.get(item, 'result.spam') === true)
        return 'spam';
      else if(_.get(item, 'result.error') === true)
        return 'error';
      else if(_.get(item, 'result') && !('spam' in item.result) && !('phish' in item.result))
        return 'suspicious';
      else
          return 'normal';
    },

    details(item, forRouter) {
        let tenant = this.$route.params.tenant || this.$route.params.id;
        return (forRouter ? '' : '/frontend') + '/tenants/edit/reports/' + tenant + '/' + item.id;
    },

    gotoDetails(item) {
        let tenant = this.$route.params.tenant || this.$route.params.id;
        let ctx = window.sessionStorage.getItem(`${tenant}|reports`) || '{}';
        try {
            ctx = JSON.parse(ctx);
        } catch(e) {
            // empty
        }
        
        const fn = () => {
            ctx.accessRequested = new Date().toISOString();
            window.sessionStorage.setItem(`${tenant}|reports`, JSON.stringify(ctx));

            let url = this.details(item, true);
            if(this.search) {
                url += '?q=' + encodeURIComponent(this.search) + '&prev=false&next=true';
            } else {
                let idx = this.items.indexOf(item);
                if(idx !== -1) {
                    let start = (this.currentPage-1) * this.perPage;
                    url += '?pos=' + (start + idx) + '&sortfield=' + this.sortBy + '&sortdir=' + (this.sortDesc ? 'desc' : 'asc');
                    if(this.status.value)
                        url += '&status=' + this.status.value;

                    url += '&prev=' + (idx > 0);
                    url += '&next=' + (idx < (this.items.length-1) || (start + this.perPage) < this.total);
                }
            }
            this.$router.push(url);
        }

        let accessRequested = ctx.accessRequested ? new Date(ctx.accessRequested) : new Date(0);
        if(accessRequested.getTime() < (Date.now() - 15 * 60 * 1000)) {
            return this.$bvModal.msgBoxConfirm('Der Zugriff auf Nachrichteninhalte darf ohne Einverständnis des Empfängers nur zum Zweck der Prüfung auf Phishing-Inhalte erfolgen. Wenn Sie fortfahren, wird dieser Zugriff protokolliert. Möchten Sie die Nachrichteninhalte einsehen?').then(value => {
                if(value)
                    fn();
            });
        }
        fn();
    },

    refresh(item) {
      let url = config.apiUrl('/report/' + (this.$route.params.tenant || this.$route.params.id) + '/' + item.id) + '?action=refresh';
      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => config.checkResponse(this, response)).then(() => {
          setTimeout(this.reload.bind(this), 500);
      });
    },

    download(item) {
      return config.apiUrl('/mail/' + (this.$route.params.tenant || this.$route.params.id) + '/' + item.mail.id) + '?download=true';
    },

    confirmation(item) {
      fetch(config.apiUrl('/confirmation/' + (this.$route.params.tenant || this.$route.params.id) + '/' + item.id), {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            hint: 'phish'
        })
      }).then(response => config.checkResponse(this, response)).then(() => {
          setTimeout(this.reload.bind(this), 500);
      });
    },

    remove(item) {
        this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
            if(value) {
                fetch(config.apiUrl('/report/' + (this.$route.params.tenant || this.$route.params.id) + '/' + item.id), {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => config.checkResponse(this, response)).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    removeSelected() {
        let _selected = _.clone(this.selected);
        this.$bvModal.msgBoxConfirm(`${_selected.length} Auswertungen werden gelöscht. Sind Sie sicher?`).then(value => {
            if(value) {
                return _selected.reduce((p, item) => {
                    return p.then(() => {
                        return fetch(config.apiUrl('/report/' + (this.$route.params.tenant || this.$route.params.id) + '/' + item.id), {
                            method: 'DELETE',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).catch(() => {});
                    });
                }, Promise.resolve()).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    toggleSelectAll(checked) {
        this.items.forEach(x => {
            x.selected = !!checked;
        });
    }
  },

  beforeMount() {
    // restore paging context
    let tenant = (this.$route.params.tenant || this.$route.params.id);
    let ctx = window.sessionStorage.getItem(`${tenant}|reports`);
    if(ctx) {
        try {
            ctx = JSON.parse(ctx);
            _.merge(this, ctx);
        } catch(e) {
            // empty
        }
    }
  }
}
</script>
