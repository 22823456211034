<template>
  <div class="tenant">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Name:" label-for="input-1"
        description="Der Name des Mandanten bzw. der Firmenname.">
        <b-form-input
          id="input-1"
          v-model="form.name"
          required
          :disabled="!isAdmin"
          placeholder="Firmen-/Mandantenname"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Kürzel:" label-for="input-2"
          description="Kürzel für E-Mail-Adressen. Es sind nur Buchstaben und Zahlen möglich.">
        <b-form-input
          id="input-2"
          v-model="form.abbreviation"
          required
          placeholder="Kürzel für E-Mail-Adressen."
          :disabled="edit || !isAdmin"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Kontakt E-Mail-Adresse:"
        label-for="input-3"
        description="Die E-Mail-Adresse für administrative Benachrichtigungen.">
        <b-form-input
          id="input-3"
          v-model="form.contact"
          type="email"
          :disabled="!isAdmin"
          placeholder="E-Mail-Adresse"
        ></b-form-input>
      </b-form-group>

      <b-form-row>
        <b-col>
          <b-form-group
            id="input-group-5"
            label="Sponsor-Token (Mandant):"
            label-for="input-5"
            description="Das Sponsor-Token des Mandaten zum Weitergeben.">
            <b-input-group>
              <b-form-input
                id="input-5"
                v-model="form.token"
                type="text"
                readonly
              ></b-form-input>
              <b-input-group-append>
                <b-button title="Neu erzeugen">
                  <b-icon icon="bootstrap-reboot"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            id="input-group-6"
            label="Sponsor-Token (Sponsor):"
            label-for="input-6"
            description="Ein fremdes Sponsor-Token eines anderen Mandanten.">
            <b-form-input
              id="input-6"
              v-model="form.sponsor"
              type="text"
              :disabled="!isAdmin"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-group
        id="input-group-4"
        label="Logo:"
        label-for="input-4"
        description="Logo des Mandanten für E-Mails/Reports. Die Datei darf max. 10 MB groß sein.">

        <div v-if="showLogo" class="wrapper">
          <img class="logo" :src="form.logo">
        </div>
        
        <b-form-file
          v-model="form.file"
          :state="Boolean(form.logo)"
          placeholder="Datei auswählen..."
          drop-placeholder="Datei hier fallen lassen..."
          :disabled="!isAdmin"
        ></b-form-file>
      </b-form-group>

      <div v-if="isAdmin">
        <b-button type="submit" variant="primary" :disabled="isSubmitDisabled">Speichern</b-button>
        <b-button type="reset" variant="default" :disabled="isResetDisabled">Abbrechen</b-button>
      </div>
    </b-form>
  </div>
</template>

<style scoped>
  button {
    margin-right: 10px;
  }
  .logo {
    max-width: 300px;
    margin-bottom: 20px;
  }
  .wrapper {
    text-align: center;
  }
  .sponsors {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
</style>

<script>
import _ from 'lodash';
import config from '../config';

export default {
  name: 'CreateTenant',
  props: ['id'],
  data() {
    return {
      form: {
        contact: '',
        name: '',
        abbreviation: '',
        logo: null,
        file: null
      },
      privileges: {},
      edit: false,
      changed: false,
      loaded: false,
      show: true
    }
  },

  computed: {
    isSubmitDisabled() {
      return !this.changed;
    },

    isResetDisabled() {
      return !this.changed && !!this.id;
    },

    showLogo() {
      return _.isString(this.form.logo);
    },

    isAdmin() {
      return !!_.get(this.privileges, 'administrator')
    }
  },

  watch: {
    form: {
      handler() {
        this.changed = !!this.loaded;
        this.$emit('changed', this.form);
      },
      deep: true
    },

    'form.name': function (newVal) {
      if(!newVal || this.edit)
        return;
      
      let abbr = _.first(newVal.split(' '));
      if(abbr) {
        abbr = abbr.toLowerCase().replace(/[^a-z0-9]/g, '');
        if(abbr.length > 10)
          abbr = abbr.substr(0, 10);
        this.form.abbreviation = abbr;
      }
    },

    'form.abbreviation': function (newVal) {
      if(!newVal || this.edit)
        return;
      
      let abbr = newVal;
      abbr = abbr.toLowerCase().replace(/[^a-z0-9]/g, '');
      if(abbr.length > 10)
        abbr = abbr.substr(0, 10);
      if(abbr !== newVal) {
        this.$nextTick(() => {
          this.form.abbreviation = abbr;
        });
      }
    },

    'form.file': function (newVal) {
      if(!newVal)
        return;
      if((newVal.type || '').indexOf('image/') !== 0) {
        this.$nextTick(() => {
          this.form.file = null;
        });
        alert('Bitte wählen Sie ein Bild!');
      } else if(newVal.size > 1024*1024*10) {
        this.$nextTick(() => {
          this.form.file = null;
        });
        alert('Die Datei ist zu groß!');
      } else {
        this.form.logo = newVal;
      }
    }
  },

  methods: {
    reload() {
      this.loaded = false;
      this.changed = false;

      if(this.id) {
        this.edit = true;
        fetch(config.apiUrl('/tenant/' + this.id), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.form = _.omit(data, 'permissions', 'privileges');
            this.privileges = data.privileges || {};
            this.$nextTick(() => (this.loaded = true));
          });
      } else {
        fetch(config.apiUrl('/tenant'), {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json'
            }
          }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.privileges = data.privileges || {};
            this.$nextTick(() => (this.loaded = true));
          });
      }
    },

    async onSubmit(evt) {
      evt.preventDefault()

      let method = 'POST';
      let url = config.apiUrl('/tenant');
      if(this.id) {
        method = 'PUT';
        url += '/' + this.id;
      }

      let data = _.omit(this.form, 'logo', 'file');
      Object.keys(data).forEach((x) => {
        if(!data[x])
          delete data[x];
      });

      if(this.form.logo && this.form.logo.indexOf('https://') !== 0) {
        let rdr = new FileReader();
        let buf = await new Promise((resolve, reject) => {
          rdr.onload = (res) => {
            resolve(res.target.result);
          };
          rdr.onerror = (err) => {
            reject(err);
          };
          rdr.readAsDataURL(this.form.logo);
        });
        data.logo = buf.substr(buf.indexOf(',')+1);
      }

      fetch(url, {
        method: method,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => config.checkResponse(this, response)).then(() => {
        this.$router.push('/tenants');
      });
    },

    onReset(evt) {
      evt.preventDefault();

      // Reset our form values
      this.form.contact = '';
      this.form.abbreviation = '';
      this.form.name = '';
      this.form.logo = null;
      
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true;
      });

      if(this.id)
        this.reload();
      else
        this.$router.push('/tenants');
    }
  },

  mounted() {
    this.reload();
  }
}
</script>
