<template>
    <div v-if="create">
        <b-form @submit="onSubmit" v-if="show">
            <h4>Einstellungen</h4>

            <b-form-group
                id="input-group-name"
                label="Name:"
                label-for="name"
                description="Beschreibender Name des Konnektors.">
                <b-form-input
                    id="name"
                    v-model="form.name"
                    type="text"
                    :placeholder="`Konnektor ${this.recipients.length+1}`">
                </b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-mode"
                label="Modus:"
                label-for="mode"
                description="Transportmodus der Adresse.">
                <b-form-select id="mode" v-model="form.mode" :options="options.mode" />
            </b-form-group>

            <b-form-group
                id="input-group-domains"
                label="Gültige E-Mail-Domänen für die Zustellung:"
                label-for="domains"
                description="Nur E-Mails an diese Domänen werden akzeptiert. Mehrere Domänen mit ',' trennen."
                v-if="form.mode === 'passthrough'">
                <b-form-input
                    id="domains"
                    v-model="form.domains"
                    type="text"
                    placeholder="Gültige E-Mail-Domänen, z.B.: *.customer.de, *.customer.onmicrosoft.com">
                </b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-smarthost"
                label="SMTP Smarthost für die Weiterleitung:"
                label-for="smarthost"
                description="Verarbeitete E-Mails werden an diesen Smarthost weitergeleitet."
                v-if="form.mode === 'passthrough'">
                <b-form-row>
                    <b-col cols="9">
                        <b-form-input
                            id="smarthost"
                            v-model="form.smarthost.host"
                            type="text"
                            placeholder="Smarthost">
                        </b-form-input>
                    </b-col>
                    <b-col cols="3">
                        <b-form-input
                            id="port"
                            v-model="form.smarthost.port"
                            type="number"
                            placeholder="Port">
                        </b-form-input>
                    </b-col>
                </b-form-row>
                <b-form-row class="mt-2">
                    <b-col cols="12">
                        <b-form-checkbox
                            id="secure"
                            v-model="form.smarthost.secure"
                            name="secure"
                            value="true">
                            Verbindung zum Smarthost per TLS/STARTTLS
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-group
                id="input-group-auth"
                label="Authentifizierung für Smarthost:"
                label-for="auth"
                v-if="form.mode === 'passthrough'">
                <b-form-row>
                    <b-col cols="6">
                        <b-form-input
                            id="username"
                            v-model="form.smarthost.auth.user"
                            type="text"
                            placeholder="Username">
                        </b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <b-form-input
                            id="password"
                            v-model="form.smarthost.auth.pass"
                            type="password"
                            placeholder="Passwort">
                        </b-form-input>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-group
                id="input-group-wait"
                label="Zustellung:"
                label-for="report"
                v-if="form.mode === 'passthrough'">
                <b-form-row>
                    <b-col cols="4">
                        <b-form-checkbox
                            id="wait"
                            v-model="form.forward.wait"
                            :disabled="!isAdmin"
                            name="wait"
                            value="true">
                            Auf Ende der Auswertung warten
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                        <b-form-checkbox
                            id="linkguard"
                            v-model="form.forward.linkguard"
                            :disabled="!isAdmin || !form.forward.wait"
                            name="linkguard"
                            value="true">
                            Link Guard
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="4">
                        <b-form-checkbox
                            id="banner"
                            v-model="form.forward.banner"
                            :disabled="!isAdmin || !form.forward.wait"
                            name="banner"
                            value="true">
                            Phishing Score zu E-Mail hinzufügen
                        </b-form-checkbox>
                    </b-col>
                </b-form-row>

                <b-form-group
                    label="Max. Phishing Score für Zustellung:"
                    label-for="maxscore"
                    description="Nur E-Mails mit einem kleineren Phishing Score werden zugestellt.">
                    <b-form-input
                        id="maxscore"
                        v-model="form.forward.maxscore"
                        :disabled="!isAdmin || !form.forward.wait"
                        type="number"
                        min="0"
                        max="10"
                        placeholder="Phishing Score">
                    </b-form-input>
                </b-form-group>
            </b-form-group>

            <hr v-if="form.mode === 'passthrough'"/>

            <b-form-group
                id="input-group-report"
                label="Auswertungen:"
                label-for="report">
                <b-form-row>
                    <b-col cols="6">
                        <b-form-checkbox
                            id="report"
                            v-model="form.report.enabled"
                            :disabled="!isAdmin"
                            name="report"
                            value="true">
                            Auswertungen per E-Mail zusenden
                        </b-form-checkbox>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group
                            label="Min. Phishing Score für Auswertungen:"
                            label-for="minscore-report"
                            description="Nur Auswertungen von E-Mails mit einem größeren Phishing Score werden zugestellt.">
                            <b-form-input
                                id="minscore"
                                v-model="form.report.minscore"
                                :disabled="!isAdmin || !form.report.enabled"
                                type="number"
                                min="0"
                                max="10"
                                placeholder="Phishing Score">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>

                <b-form-row>
                    <b-col cols="12">
                        <b-form-group
                            id="input-group-report-valid"
                            label="Gültige E-Mail-Empfänger für Auswertungen:"
                            label-for="report-valid"
                            placeholder="Gültige E-Mail-Empfänger, z.B.: *.customer.de, *.customer.onmicrosoft.com">
                            <b-form-input
                                id="report-valid"
                                v-model="form.report.valid"
                                type="text"
                                :disabled="!isAdmin || !form.report.enabled"
                                placeholder="Gültige E-Mail-Empfänger">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </b-form-group>

            <b-form-group
                id="input-group-lang"
                label="Sprache:"
                label-for="lang"
                description="Sprache für Auswertungen.">
                <b-form-select id="lang" v-model="form.lang" :options="options.lang" />
            </b-form-group>

            <b-form-group
                id="input-group-confirm"
                label="E-Mail-Adresse für Bestätigungen:"
                label-for="confirm"
                :description="emailDescription">
                <b-form-input
                    id="confirm"
                    v-model="form.confirm"
                    type="email"
                    placeholder="E-Mail-Adresse">
                </b-form-input>
            </b-form-group>

            <b-form-group
                id="input-group-retention"
                label="Aufbewahrungsdauer:"
                label-for="retention"
                description="Aufbewahrungsdauer (in Tagen) für Nachrichten an diese E-Mail-Adresse.">
                <b-form-input
                    id="retention"
                    v-model="form.retention"
                    type="number"
                    max="3650">
                </b-form-input>
            </b-form-group>

            <div v-if="isGlobalAdmin">
                <hr />

                <h4>Sicherheit</h4>

                <b-form-group id="confirmation" label="Bestätigungen:"
                    description="Gruppennamen oder Ids der Gruppen dieses Konnektors, die Bestätigungen sehen und ändern können.">

                    <b-input-group class="group" v-for="x in permissions.confirmation" :key="permissions.confirmation.indexOf(x)">
                        <b-form-input v-model="permissions.confirmation[permissions.confirmation.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
                        <b-input-group-append>
                            <b-button title="Entfernen" @click="permissions.confirmation.splice(permissions.confirmation.indexOf(x), 1)">
                                <b-icon icon="trash"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-button size="sm" class="mr-1 add" @click="permissions.confirmation.push({isnew: true, value: ''})">
                        <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
                    </b-button>
                </b-form-group>

                <b-form-group id="mailbox" label="Mailbox-Zugriff:"
                    description="Gruppennamen oder Ids der Gruppen dieses Konnektors, die Zugriff auf die E-Mails in der Mailbox haben.">

                    <b-input-group class="group" v-for="x in permissions.mailbox" :key="permissions.mailbox.indexOf(x)">
                        <b-form-input v-model="permissions.mailbox[permissions.mailbox.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
                        <b-input-group-append>
                            <b-button title="Entfernen" @click="permissions.mailbox.splice(permissions.mailbox.indexOf(x), 1)">
                                <b-icon icon="trash"/>
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                    <b-button size="sm" class="mr-1 add" @click="permissions.mailbox.push({isnew: true, value: ''})">
                        <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
                    </b-button>
                </b-form-group>
            </div>

            <hr />

            <b-button type="submit" variant="primary">Speichern</b-button>
            <b-button type="reset" variant="default" :to="'/tenants/edit/recipients/' + $route.params.id">Abbrechen</b-button>
        </b-form>
    </div>
    <div v-else>
        <div v-if="edit">
            <b-form @submit="onSubmit" v-if="show">
                <h4>Einstellungen</h4>

                <b-form-group
                    id="input-group-name"
                    label="Name:"
                    label-for="name"
                    description="Beschreibender Name des Konnektors.">
                    <b-form-input
                        id="name"
                        v-model="form.name"
                        type="text">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-mode"
                    label="Modus:"
                    label-for="mode"
                    description="Transportmodus der Adresse.">
                    <b-form-select id="mode" v-model="form.mode" :options="options.mode" disabled />
                </b-form-group>

                <b-form-group
                    id="input-group-domains"
                    label="Gültige E-Mail-Domänen für die Zustellung:"
                    label-for="domains"
                    placeholder="Gültige E-Mail-Domänen, z.B.: *.customer.de, *.customer.onmicrosoft.com"
                    v-if="form.mode === 'passthrough'">
                    <b-form-input
                        id="domains"
                        v-model="form.domains"
                        type="text"
                        :disabled="!isAdmin"
                        placeholder="Gültige E-Mail-Domänen">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-smarthost"
                    label="SMTP Smarthost für die Weiterleitung:"
                    label-for="smarthost"
                    description="Verarbeitete E-Mails werden an diesen Smarthost weitergeleitet."
                    v-if="form.mode === 'passthrough'">
                    <b-form-row>
                        <b-col cols="9">
                            <b-form-input
                                id="smarthost"
                                v-model="form.smarthost.host"
                                type="text"
                                :disabled="!isAdmin"
                                placeholder="Smarthost">
                            </b-form-input>
                        </b-col>
                        <b-col cols="3">
                            <b-form-input
                                id="port"
                                v-model="form.smarthost.port"
                                type="number"
                                :disabled="!isAdmin"
                                placeholder="Port">
                            </b-form-input>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mt-2">
                        <b-col cols="12">
                            <b-form-checkbox
                                id="secure"
                                v-model="form.smarthost.secure"
                                :disabled="!isAdmin"
                                name="secure"
                                value="true">
                                Verbindung zum Smarthost per TLS/STARTTLS
                            </b-form-checkbox>
                        </b-col>
                    </b-form-row>
                </b-form-group>

                <b-form-group
                    id="input-group-auth"
                    label="Authentifizierung für Smarthost:"
                    label-for="auth"
                    v-if="form.mode === 'passthrough'">
                    <b-form-row>
                        <b-col cols="6">
                            <b-form-input
                                id="username"
                                v-model="form.smarthost.auth.user"
                                type="text"
                                :disabled="!isAdmin"
                                placeholder="Username">
                            </b-form-input>
                        </b-col>
                        <b-col cols="6">
                            <b-form-input
                                id="password"
                                v-model="form.smarthost.auth.pass"
                                type="password"
                                :disabled="!isAdmin"
                                placeholder="Passwort">
                            </b-form-input>
                        </b-col>
                    </b-form-row>
                </b-form-group>

                <b-form-group
                    id="input-group-wait"
                    label="Zustellung:"
                    label-for="report"
                    v-if="form.mode === 'passthrough'">
                    <b-form-row>
                        <b-col cols="4">
                            <b-form-checkbox
                                id="wait"
                                v-model="form.forward.wait"
                                :disabled="!isAdmin"
                                name="wait"
                                value="true">
                                Auf Ende der Auswertung warten
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="4">
                            <b-form-checkbox
                                id="linkguard"
                                v-model="form.forward.linkguard"
                                :disabled="!isAdmin || !form.forward.wait"
                                name="linkguard"
                                value="true">
                                Link Guard
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="4">
                            <b-form-checkbox
                                id="banner"
                                v-model="form.forward.banner"
                                :disabled="!isAdmin || !form.forward.wait"
                                name="banner"
                                value="true">
                                Phishing Score zu E-Mail hinzufügen
                            </b-form-checkbox>
                        </b-col>
                    </b-form-row>

                    <b-form-group
                        label="Max. Phishing Score für Zustellung:"
                        label-for="maxscore"
                        description="Nur E-Mails mit einem kleineren Phishing Score werden zugestellt.">
                        <b-form-input
                            id="maxscore"
                            v-model="form.forward.maxscore"
                            :disabled="!isAdmin || !form.forward.wait"
                            type="number"
                            min="0"
                            max="10"
                            placeholder="Phishing Score">
                        </b-form-input>
                    </b-form-group>
                </b-form-group>

                <hr v-if="form.mode === 'passthrough'"/>

                <b-form-group
                    id="input-group-address"
                    label="E-Mail-Adresse:"
                    label-for="address"
                    description="E-Mail-Adresse"
                    v-if="form.mode === 'inbox'">
                    <b-input-group>
                        <b-form-input
                        id="address"
                        v-model="form.address"
                        type="email"
                        placeholder="E-Mail-Adresse"
                        :disabled="edit || !isAdmin"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-button @click="copy" title="Kopieren">
                                <!-- needed for copying -->
                                <input type="text" class="off" :value="form.address" />
                                <b-icon icon="clipboard" />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <b-form-group
                    id="input-group-report"
                    label="Auswertungen:"
                    label-for="report">
                    <b-form-row>
                        <b-col cols="6">
                            <b-form-checkbox
                                id="report"
                                v-model="form.report.enabled"
                                :disabled="!isAdmin"
                                name="report"
                                value="true">
                                Auswertungen per E-Mail zusenden
                            </b-form-checkbox>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group
                                label="Min. Phishing Score für Auswertungen:"
                                label-for="minscore-report"
                                description="Nur Auswertungen von E-Mails mit einem größeren Phishing Score werden zugestellt.">
                                <b-form-input
                                    id="minscore"
                                    v-model="form.report.minscore"
                                    :disabled="!isAdmin || !form.report.enabled"
                                    type="number"
                                    min="0"
                                    max="10"
                                    placeholder="Phishing Score">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>

                    <b-form-row>
                        <b-col cols="12">
                            <b-form-group
                                id="input-group-report-valid"
                                label="Gültige E-Mail-Empfänger für Auswertungen:"
                                label-for="report-valid"
                                placeholder="Gültige E-Mail-Empfänger, z.B.: *.customer.de, *.customer.onmicrosoft.com">
                                <b-form-input
                                    id="report-valid"
                                    v-model="form.report.valid"
                                    type="text"
                                    :disabled="!isAdmin || !form.report.enabled"
                                    placeholder="Gültige E-Mail-Empfänger">
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-form-group>

                <b-form-group
                    id="input-group-lang"
                    label="Sprache:"
                    label-for="lang"
                    description="Sprache für Auswertungen.">
                    <b-form-select id="lang" v-model="form.lang" :disabled="!isAdmin" :options="options.lang" />
                </b-form-group>

                <b-form-group
                    id="input-group-confirm"
                    label="E-Mail-Adresse für Bestätigungen:"
                    label-for="confirm"
                    :description="emailDescription">
                    <b-form-input
                        id="confirm"
                        v-model="form.confirm"
                        :disabled="!isAdmin"
                        type="email"
                        placeholder="E-Mail-Adresse">
                    </b-form-input>
                </b-form-group>

                <b-form-group
                    id="input-group-retention"
                    label="Aufbewahrungsdauer:"
                    label-for="retention"
                    description="Aufbewahrungsdauer (in Tagen) für Nachrichten an diese E-Mail-Adresse.">
                    <b-form-input
                        id="retention"
                        v-model="form.retention"
                        :disabled="!isAdmin"
                        type="number"
                        max="3650">
                    </b-form-input>
                </b-form-group>

                <div v-if="isGlobalAdmin">
                    <hr />

                    <h4>Sicherheit</h4>

                    <b-form-group id="confirmation" label="Bestätigungen:"
                        description="Gruppennamen oder Ids der Gruppen dieses Konnektors, die Bestätigungen sehen und ändern können.">

                        <b-input-group class="group" v-for="x in permissions.confirmation" :key="permissions.confirmation.indexOf(x)">
                            <b-form-input v-model="permissions.confirmation[permissions.confirmation.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
                            <b-input-group-append v-if="isAdmin">
                                <b-button title="Entfernen" @click="permissions.confirmation.splice(permissions.confirmation.indexOf(x), 1)">
                                    <b-icon icon="trash"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <b-button size="sm" class="mr-1 add" @click="permissions.confirmation.push({isnew: true, value: ''})" v-if="isAdmin">
                            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
                        </b-button>
                    </b-form-group>

                    <b-form-group id="mailbox" label="Mailbox-Zugriff:"
                        description="Gruppennamen oder Ids der Gruppen dieses Konnektors, die Zugriff auf die E-Mails in der Mailbox haben.">

                        <b-input-group class="group" v-for="x in permissions.mailbox" :key="permissions.mailbox.indexOf(x)">
                            <b-form-input v-model="permissions.mailbox[permissions.mailbox.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
                            <b-input-group-append v-if="isAdmin">
                                <b-button title="Entfernen" @click="permissions.mailbox.splice(permissions.mailbox.indexOf(x), 1)">
                                    <b-icon icon="trash"/>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                        <b-button size="sm" class="mr-1 add" @click="permissions.mailbox.push({isnew: true, value: ''})" v-if="isAdmin">
                            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
                        </b-button>
                    </b-form-group>

                </div>
                <div v-if="isAdmin">
                    <hr />
                    
                    <b-button type="submit" variant="primary">Speichern</b-button>
                    <b-button type="reset" variant="default" :to="'/tenants/edit/recipients/' + $route.params.id">Abbrechen</b-button>
                </div>
            </b-form>
        </div>
        <div v-else>
            <b-button size="sm" class="mr-1 create" :to="'/tenants/edit/recipients/' + $route.params.id + '?create=true'" v-if="isAdmin">
                <b-icon icon="file-plus" /> <span class="d-none d-sm-inline">Neuer Konnektor</span>
            </b-button>
            <b-table ref="recipients" striped hover primary-key="address" :items="recipients" :fields="fields" @row-clicked="details">
                <template #head(selected)>
                    <b-form-checkbox id="selected" v-model="allSelected" @change="toggleSelectAll">
                    </b-form-checkbox>
                </template>
                <template #head(actions)>
                    <div class="actions">
                        <b-button size="sm" @click="removeSelected()" title="Ausgewählte Konnektoren löschen" class="mr-1" :disabled="selected.length === 0">
                            <b-icon icon="trash" />
                        </b-button>
                    </div>
                </template>
                <template #cell(selected)="row">
                    <b-form-checkbox :id="'selected-' + row.item.address" v-model="row.item.selected">
                    </b-form-checkbox>
                </template>
                <template #cell(mode)="row">
                    <div class="mode">
                        {{displayMode(row.item.mode)}}
                    </div>
                </template>
                <template #cell(address)="row">
                    <div class="address">
                        {{row.item.mode === 'inbox' ? row.item.address : row.item.domains}}
                        <b-button size="sm" variant="default" @click="copy" class="ml-1" title="Kopieren">
                            <!-- needed for copying -->
                            <input type="text" class="off" :value="row.item.mode === 'inbox' ? row.item.address : row.item.domains" />
                            <b-icon icon="clipboard" />
                        </b-button>
                    </div>
                </template>
                <template #cell(actions)="row">
                    <div class="actions">
                        <b-button size="sm" @click="row.toggleDetails" class="mr-1" v-if="isAdmin">
                            <b-icon icon="cloud-upload" /> <span class="d-none d-sm-inline">Upload</span>
                        </b-button>
                        <b-button size="sm" @click="remove(row.item)" class="mr-1" v-if="isAdmin">
                            <b-icon icon="trash" /> <span class="d-none d-sm-inline">Löschen</span>
                        </b-button>
                    </div>
                </template>
                <template #row-details="row">
                    <b-form>
                        <b-form-group
                            id="input-group-2">
                            <b-form-file
                                v-model="email"
                                placeholder="EML-Datei hochladen ..."
                                drop-placeholder="Datei hier fallen lassen ..."
                                @change="emailChanged(row.item)">
                            </b-form-file>
                        </b-form-group>
                    </b-form>
                </template>
            </b-table>
        </div>
    </div>
</template>

<style scoped>
    .create {
        float: right;
        margin-bottom: 20px;
    }
    .actions {
        text-align: right;
    }
    .off {
        display: none !important;
    }
    .address {
        white-space: nowrap;
    }
    button.add {
        margin-top: 10px;
    }
    .group {
        margin-bottom: 5px;
    }
</style>

<script>
import _ from 'lodash';
import config from '../config';

export default {
  name: 'Recipients',
  props: ['id', 'address'],
  data() {
    return {
        fields: [{
            key: 'selected',
            label: ''
        }, {
            key: 'name',
            label: 'Name',
            sortable: true
        }, {
            key: 'address',
            label: 'E-Mail-Adresse / Domänen',
            sortable: true
        }, {
            key: 'mode',
            label: 'Modus'
        }, {
            key: 'lang',
            label: 'Sprache'
        }, {
            key: 'actions', label: ''
        }],
        recipients: [],
        form: {
            name: null,
            mode: 'inbox',
            smarthost: {
                auth: {}
            },
            forward: {},
            report: {},
            lang: 'de',
            confirm: '',
            use: 'check',
            retention: 30
        },
        options: {
            mode: [{
                value: 'inbox',
                text: this.displayMode('inbox')
             }, {
                value: 'passthrough',
                text: this.displayMode('passthrough')
             }],
            use: [{
                value: 'check',
                text: this.displayUse('check')
             }, {
                value: 'import',
                text: this.displayUse('import')
             }],
             lang: ['de']
        },
        permissions: {
            confirmation: [],
            mailbox: [],
        },
        privileges: {},
        changed: false,
        show: true,
        email: null,
        allSelected: false
    }
  },

  computed: {
      create() {
          return !!_.get(this.$route, 'query.create');
      },
      edit() {
          return !!this.address;
      },
      emailDescription() {
          return this.form.use === 'check' ?
            'Die E-Mail-Adresse für manuelle Entscheidungen bei unklaren Ergebnissen.' :
            'Die E-Mail-Adresse für Benachrichtigungen zur Bestätigung des Imports.';
      },
      isGlobalAdmin() {
        return !!_.get(this.privileges, 'global') && !!_.get(this.privileges, 'administrator')
      },
      isAdmin() {
        return !!_.get(this.privileges, 'administrator')
      },
      selected() {
          return this.recipients.filter(x => !!x.selected);
      }
  },

  watch: {
      form: {
        handler() {
            this.changed = true;
        },
        deep: true
      },
      permissions: {
        handler() {
            this.changed = true;
        },
        deep: true
      },
      address() {
          this.reload();
      }
  },

  methods: {
    reset() {
        Object.assign(this.$data, this.$options.data.apply(this));
    },
    reload() {
      this.reset();
      if(this.id && this.address) {
        fetch(config.apiUrl('/recipient/' + this.id + '/' + this.address), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            if(!data.smarthost)
                data.smarthost = {};
            if(!data.smarthost.auth)
                data.smarthost.auth = {};
            if(!data.forward)
                data.forward = {};
            this.form = _.omit(data, 'permissions', 'privileges');
            if(data.permissions) {
                this.permissions = {
                    confirmation: _.get(data, 'permissions.confirmation', []).map(x => { return {value: x}; }),
                    mailbox: _.get(data, 'permissions.mailbox', []).map(x => { return {value: x}; })
                };
            }
            this.privileges = data.privileges || {};
          });
      }
      else if(this.id) {
        fetch(config.apiUrl('/recipient/' + this.id), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.recipients = data.items.map((x) => {
                x.selected = false;
                return x;
            });
            this.privileges = data.privileges || {};
            this.allSelected = false;
          });
      }
    },

    displayUse(val) {
        if(val === 'check')
            return 'Prüfen';
        else if(val === 'import')
            return 'Importieren';
        else
            return val;
    },

    displayMode(val) {
        if(val === 'inbox')
            return 'Zustellung';
        else if(val === 'passthrough')
            return 'Weiterleitung';
        else
            return val;
    },

    details(item) {
        this.$router.push('/tenants/edit/recipients/' + this.id + '/' + item.address);
    },

    copy(ev) {
        ev.preventDefault();
        ev.stopPropagation();

        var btn = ev.target;
        while(btn && !btn.nodeName.match(/button/i))
            btn = btn.parentElement;
        if(!btn)
            return;

        var inp = btn.querySelector('input');
        if(!inp)
            return;
        
        var link = inp.value;
        var fn = function(ev) {
            if(ev && ev.clipboardData)
                ev.clipboardData.setData('text/plain', link);
            else if(typeof(window) !== 'undefined' && window.clipboardData)
                // IE
                window.clipboardData.setData('Text', link);
            else
                return false;
            
            if(ev)
                ev.preventDefault();
        };
        
        try {
            // select input
            inp.focus();
            inp.select();

            if(typeof(window) !== 'undefined' && window.clipboardData)
                // IE
                return fn();

            // add event listener and trigger 'copy' command
            document.addEventListener('copy', fn, {
                capture: true,
                once: true
            });
            document.execCommand('copy');
        } catch(e) {
            console.log(e.message || e);
        }
    },

    remove(item) {
        this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
            if(value) {
                fetch(config.apiUrl('/recipient/' + this.id + '/' + item.address), {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => config.checkResponse(this, response)).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    removeSelected() {
        let _selected = _.clone(this.selected);
        this.$bvModal.msgBoxConfirm(`${_selected.length} Konnektoren werden gelöscht. Sind Sie sicher?`).then(value => {
            if(value) {
                return _selected.reduce((p, item) => {
                    return p.then(() => {
                        return fetch(config.apiUrl('/recipient/' + this.id + '/' + item.address), {
                            method: 'DELETE',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).catch(() => {});
                    });
                }, Promise.resolve()).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    toggleSelectAll(checked) {
        this.recipients.forEach(x => {
            x.selected = !!checked;
        });
    },

    onSubmit(evt) {
      evt.preventDefault()

      let data = _.assign({
          tenant: this.id
      }, this.form);
      Object.keys(data).forEach((x) => {
        if(data[x] === '')
            data[x] = null;
        if(x === 'retention')
            data[x] = parseInt(data[x]);
        if(['true', 'false'].indexOf(data[x]) !== -1)
            data[x] = data[x] === 'true';
      });

      if(_.get(data, 'smarthost.port'))
        data.smarthost.port = parseInt(data.smarthost.port);
      if(_.isEmpty(data.smarthost.auth))
        delete data.smarthost.auth;
      if(_.isEmpty(data.smarthost))
        delete data.smarthost;

      if(_.get(data, 'forward.maxscore'))
        data.forward.maxscore = parseFloat(data.forward.maxscore);
      if(_.isEmpty(data.forward))
        delete data.forward;
    
      if(_.get(data, 'report.minscore'))
        data.report.minscore = parseFloat(data.report.minscore);
      else
        delete data.report.minscore;
      if(_.isEmpty(data.report.valid))
        delete data.report.valid;

      data.permissions = {
        confirmation: this.permissions.confirmation.map(x => x.value),
        mailbox: this.permissions.mailbox.map(x => x.value)
      };

      let url = config.apiUrl('/recipient');
      if(this.address)
        url += '/' + this.id + '/' + this.address;
    
      fetch(url, {
        method: this.address ? 'PUT' : 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => config.checkResponse(this, response)).then(() => {
        this.$router.push('/tenants/edit/recipients/' + this.id);
        this.$nextTick(() => {
            this.reload()
        });
      });
    },

    emailChanged(item) {
        setTimeout(() => {
            if(!this.email)
                return;
            
            if((this.email.type || '') !== 'message/rfc822') {
                this.$nextTick(() => {
                    this.email = null;
                });
                alert('Falsches Format! Bitte wählen Sie eine E-Mail im Format .eml');
            } else {
                let url = config.apiUrl('/mail/' + this.id) + '?recipient=' + encodeURIComponent(item.address) + '&sender=' + encodeURIComponent('anonymous@mr-mail-defense.com');
                return fetch(url, {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    },
                    body: this.email
                }).then(response => config.checkResponse(this, response)).
                then(() => {
                    this.email = null;
                    this.reload();
                }).
                catch((err) => {
                    alert(err.message);
                });
            }
        });
    },

    updateRetention() {
        this.form.retention = this.form.use === 'import' ? 180 : 30;
    }
  },

  mounted() {
    this.reload();
  }
}
</script>
