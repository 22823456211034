<template>
  <div class="page tenants">
    <h1>Alle Mandanten</h1>
    <Tenants/>
  </div>
</template>

<script>
// @ is an alias to /src
import Tenants from '@/components/Tenants.vue';

export default {
  props: ['profile'],
  components: {
    Tenants
  }
}
</script>
