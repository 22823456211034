<template>
    <div v-if="confirmation">
        <InternalConfirmation :id="confirmation" @reload="reload"/>
    </div>
    <div class="confirmations" v-else>
        <b-table :key="created" id="confirmations" ref="confirmations" outlined primary-key="id" 
            :items="confirmations" 
            :fields="fields" 
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @row-clicked="gotoDetails">
            <template #head(selected)>
                <b-form-checkbox id="selected" v-model="allSelected" @change="toggleSelectAll">
                </b-form-checkbox>
            </template>
            <template #head(actions)>
                <div class="actions">
                    <b-button size="sm" @click="removeSelected()" title="Ausgewählte Bestätigungen löschen" class="mr-1" :disabled="selected.length === 0">
                        <b-icon icon="trash" />
                    </b-button>
                </div>
            </template>
            <template #cell()="row">
                <b-link :href="details(row.item)">{{row.value}}</b-link>
            </template>
            <template #cell(selected)="row">
                <b-form-checkbox :id="'selected-' + row.item.id" v-model="row.item.selected">
                </b-form-checkbox>
            </template>
            <template #cell(score)="row">
                <b-link :href="details(row.item)">{{row.item.result.score}}</b-link>
            </template>
            <template #cell(actions)="row">
                <div class="actions">
                    <b-button size="sm" @click="remove(row.item)" title="Löschen" class="mr-1">
                        <b-icon icon="trash" /> <span class="d-none d-sm-inline">Löschen</span>
                    </b-button>
                </div>
            </template>
            <template #row-details="row">
                <b-link :href="details(row.item)"><small>{{subject(row.item)}}</small></b-link>
            </template>
        </b-table>
        <div class="pagination-wrapper">
            <b-form-select v-model="count.value" :options="count.options" />
            <b-pagination
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                aria-controls="confirmations"
                align="center"
            ></b-pagination>
        </div>
    </div>
</template>

<style>
    .actions {
        text-align: right;
        white-space: nowrap;
    }
    tr.b-table-has-details {
        cursor: pointer;
    }
    tr.b-table-details small {
        color: #333;
    }
</style>

<style scoped lang="scss">
table {
    a, a:visited, a:hover, a:focus, a:active {
        text-decoration: none;
        color: inherit;
    }
}
.pagination-wrapper {
    display: flex;
    justify-content: center;

    select {
        width: 80px;
        margin-right: 20px;
    }
}
</style>

<script>
import _ from 'lodash';
import config from '../config';
import InternalConfirmation from './InternalConfirmation.vue';

export default {
  components: { InternalConfirmation },
  name: 'Confirmations',
  props: ['id', 'confirmation'],

  data() {
    return {
        fields: [{
            key: 'selected',
            label: ''
        }, {
            key: 'from',
            label: 'Sender',
            sortable: true
        }, {
            key: 'to',
            label: 'Empfänger',
            sortable: false
        }, {
            key: 'created',
            label: 'Erstellt',
            sortable: true,
            formatter: 'displayDate'
        }, {
            key: 'actions', label: ''
        }],
        count: {
            value: 10,
            options: [{
                value: 10,
                text: 10
            }, {
                value: 25,
                text: 25
            }, {
                value: 50,
                text: 50
            }, {
                value: 100,
                text: 100
            }]
        },
        currentPage: 1,
        perPage: 10,
        total: 0,
        sortBy: 'created',
        sortDesc: true,
        items: [],
        allSelected: false
    };
  },

  computed: {
      created() {
          return Date.now();
      },

      selected() {
          return this.items.filter(x => !!x.selected);
      }
  },

  watch: {
      'count.value'() {
          this.perPage = this.count.value;
      }
  },

  methods: {
    reload() {
        this.$root.$emit('bv::refresh::table', 'confirmations');
    },

    savePaging(ctx) {
        let tenant = (this.$route.params.tenant || this.$route.params.id);
        window.sessionStorage.setItem(`${tenant}|confirmations`, JSON.stringify({
            currentPage: ctx.currentPage,
            perPage: ctx.perPage,
            sortBy: ctx.sortBy,
            sortDesc: ctx.sortDesc,
            total: this.total,
            count: {
                value: this.count.value
            }
        }));
    },

    confirmations(ctx) {
      let start = (ctx.currentPage-1) * ctx.perPage;
      let url = config.apiUrl('/confirmation') + '?tenant=' + (this.$route.params.tenant || this.$route.params.id);

      return fetch(url + '&start=' + start + '&count=' + ctx.perPage + '&sortfield=' + ctx.sortBy + '&sortdir=' + (ctx.sortDesc ? 'desc' : 'asc'), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
        then(response => config.checkResponse(this, response)).
        then(data => {
            this.total = data.total || 0;
            let _items = data.items || [];
            this.items = _items.map((x) => {
                return _.assign(x, {
                    selected: false,
                    from: _.get(x, 'mail.info.from', [x.sender]).join(', '),
                    to: _.get(x, 'mail.info.to', [x.recipient]).join(', '),
                    _showDetails: true
                });
            });
            this.allSelected = false;

            // preserve paging postition
            this.savePaging(ctx);

            return this.items;
        }).catch((err) => {
            console.log(err);
        });
    },

    displayDate(val) {
        return new Date(val).toLocaleString();
    },

    displayUse(val) {
        if(val === 'check')
            return 'Prüfen';
        else if(val === 'import')
            return 'Importieren';
        else
            return val;
    },

    subject(item) {
        return _.get(item, 'mail.info.subject') || '';
    },

    details(item, forRouter) {
        let tenant = this.$route.params.tenant || this.$route.params.id;
        return (forRouter ? '' : '/frontend') + '/tenants/edit/confirmations/' + tenant + '/' + item.id;
    },

    gotoDetails(item) {
        this.$router.push(this.details(item, true));
    },

    remove(item) {
        this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
            let tenant = this.$route.params.tenant || this.$route.params.id;
            if(value) {
                fetch(config.apiUrl('/confirmation/' + tenant + '/' + item.id), {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => config.checkResponse(this, response)).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    removeSelected() {
        let _selected = _.clone(this.selected);
        this.$bvModal.msgBoxConfirm(`${_selected.length} Bestätigungen werden gelöscht. Sind Sie sicher?`).then(value => {
            if(value) {
                return _selected.reduce((p, item) => {
                    return p.then(() => {
                        return fetch(config.apiUrl('/confirmation/' + (this.$route.params.tenant || this.$route.params.id) + '/' + item.id), {
                            method: 'DELETE',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }).catch(() => {});
                    });
                }, Promise.resolve()).then(() => {
                    setTimeout(() => this.reload());
                });
            }
        }).catch(err => {
            console.log(err);
            setTimeout(() => this.reload());
        });
    },

    toggleSelectAll(checked) {
        this.items.forEach(x => {
            x.selected = !!checked;
        });
    }
  },

  beforeMount() {
    // restore paging context
    let tenant = (this.$route.params.tenant || this.$route.params.id);
    let ctx = window.sessionStorage.getItem(`${tenant}|confirmations`);
    if(ctx) {
        try {
            ctx = JSON.parse(ctx);
            _.merge(this, ctx);
        } catch(e) {
            // empty
        }
    }
  }
}
</script>
