<template>
  <div class="container page edit-tenant">
    <div class="row">
      <b-button type="button" variant="link" class="back" title="Zur Mandantenauswahl" to="/tenants">
        <b-icon icon="arrow-left"/>
      </b-button>
      <h1>Mandant: <em>{{name}}</em></h1>
    </div>
    <div class="row">
      <b-nav vertical pills class="col-sm-3 mb-3">
        <b-nav-item :active="$route.params.section === 'stats'" :to="'/tenants/edit/stats/' + $route.params.id">Statistiken</b-nav-item>
        <b-nav-item :active="$route.params.section === 'profile'" :to="'/tenants/edit/profile/' + $route.params.id">Allgemein</b-nav-item>
        <b-nav-item :active="$route.params.section === 'security'" :to="'/tenants/edit/security/' + $route.params.id" v-if="isGlobalAdmin">Sicherheit</b-nav-item>
        <b-nav-item :active="$route.params.section === 'recipients'" :to="'/tenants/edit/recipients/' + $route.params.id" v-if="isAdmin">Konnektoren</b-nav-item>
        <b-nav-item :active="$route.params.section === 'customlists'" :to="'/tenants/edit/customlists/' + $route.params.id" v-if="isAdmin">Listen</b-nav-item>
        <b-nav-item :active="$route.params.section === 'training'" :to="'/tenants/edit/training/' + $route.params.id" v-if="isAdmin">Training</b-nav-item>
        <b-nav-item :active="$route.params.section === 'confirmations'" :to="'/tenants/edit/confirmations/' + $route.params.id" v-if="isAdmin || isConfirmation">Bestätigungen</b-nav-item>
        <b-nav-item :active="$route.params.section === 'reports'" :to="'/tenants/edit/reports/' + $route.params.id" v-if="isAdmin || isMailbox">Mailbox</b-nav-item>
        <b-nav-item :active="$route.params.section === 'audittrail'" :to="'/tenants/edit/audittrail/' + $route.params.id" v-if="isAdmin || isMailbox">Überwachung</b-nav-item>
      </b-nav>
      <div class="col-sm-9">
        <CreateTenant :id="$route.params.id" v-if="$route.params.section == 'profile'" @changed="onChanged"/>
        <Recipients :id="$route.params.id" :address="$route.params.item" v-if="$route.params.section == 'recipients'" />
        <Imports :id="$route.params.id" :mail="$route.params.item" v-if="$route.params.section == 'imports'" />
        <Confirmations :id="$route.params.id" :confirmation="$route.params.item" v-if="$route.params.section == 'confirmations'" />
        <Reports :id="$route.params.id" :report="$route.params.item" v-if="$route.params.section == 'reports'" />
        <Stats :id="$route.params.id" :report="$route.params.item" v-if="$route.params.section == 'stats'" />
        <Security :id="$route.params.id" :report="$route.params.item" v-if="$route.params.section == 'security'" />
        <Customlists :id="$route.params.id" :list="$route.params.item" v-if="$route.params.section == 'customlists'" />
        <Training :id="$route.params.id" :list="$route.params.item" v-if="$route.params.section == 'training'" />
        <AuditTrail :id="$route.params.id" :list="$route.params.item" v-if="$route.params.section == 'audittrail'" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.back {
  float: left;
  margin-right: 10px;
}
</style>

<script>
// @ is an alias to /src
import CreateTenant from '@/components/CreateTenant.vue';
import Recipients from '@/components/Recipients.vue';
import Confirmations from '@/components/Confirmations.vue';
import Imports from '@/components/Imports.vue';
import Reports from '@/components/Reports.vue';
import Stats from '@/components/Stats.vue';
import Security from '@/components/Security.vue';
import Customlists from '@/components/Customlists.vue';
import Training from '@/components/Training.vue';
import AuditTrail from '@/components/AuditTrail.vue';

import _ from 'lodash';
import config from '../config';

export default {
  props: ['profile'],
  
  data() {
    return {
      client: {
        model: null
      },
      privileges: {}
    };
  },

  computed: {
    name() {
      return _.get(this, 'client.model.name') || '';
    },

    isGlobalAdmin() {
      return !!_.get(this.privileges, 'global') && !!_.get(this.privileges, 'administrator');
    },

    isAdmin() {
      return !!_.get(this.privileges, 'administrator');
    },

    isMailbox() {
      return !!_.get(this.privileges, 'mailbox');
    },

    isConfirmation() {
      return !!_.get(this.privileges, 'confirmation');
    }
  },

  methods: {
    onChanged(model) {
      this.client.model = model;
    }
  },

  mounted() {
    let id = this.$route.params.id;
    if(id) {
      fetch(config.apiUrl('/tenant/' + id), {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      }).
      then(response => config.checkResponse(this, response)).
      then(data => {
          this.client.model = _.omit(data, 'permissions', 'privileges');
          this.privileges = data.privileges;
      });
    }
  },
  
  components: {
    CreateTenant,
    Recipients,
    Confirmations,
    Imports,
    Reports,
    Stats,
    Security,
    Customlists,
    Training,
    AuditTrail
  }
}
</script>