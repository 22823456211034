<template>
    <div class="internal-mail">
        <h2>{{subject}}</h2>
        <div class="screenshot" v-if="!html">
          <div class="loading" v-if="!loaded">
            <div>
              <b-spinner label="Spinning"></b-spinner>
            </div>
            <div>
              Lade Screenshot ...
            </div>
          </div>
          <img border="0" :src="screenshot" @load="onScreenshotLoaded" @error="onScreenshotFailed" />
          <b-button class="load-html" type="button" variant="warning" @click="loadHtml">Mail als HTML anzeigen</b-button>
        </div>
        <div class="html" v-else>
          <h3>ACHTUNG: HTML-Ansicht der Mail. Alle Links sind aktiv!</h3>
          <iframe id="iframe" border="0" :src="html" @load="onLoaded"></iframe>
        </div>
    </div>
</template>

<style scoped>
.screenshot {
  margin-top: 20px;
  text-align: center;
}
.loading > div {
  margin-bottom: 1em;
}
img {
  width: 100%;
  height: auto;
}
.load-html {
  margin-top: 20px;
}
.html {
  margin-top: 20px;
}
.html > h3 {
  color: #ff0000;
}
iframe {
    border: none;
    width: 100%;
    overflow-y: visible;
}
</style>

<script>
import _ from 'lodash';
import config from '../config';

export default {
  name: 'InternalMail',
  props: ['id'],

  data() {
    return {
        mail: null,
        html: null,
        loaded: false
    }
  },

  computed: {
    subject() {
      return _.get(this, 'mail.info.subject') || '';
    },

    screenshot() {
        let url = _.get(this, 'mail.screenshot');
        return url ? config.apiUrl(url) : url;
    }
  },

  watch: {
    mail() {
      this.$emit('changed', this.mail);
    }
  },

  methods: {
    reload() {
      if(this.id) {
        fetch(config.apiUrl('/mail/' + (this.$route.params.tenant || this.$route.params.id) + '/' + this.id), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.mail = data;
          });
      }
    },

    loadHtml() {
        let html = _.get(this, 'mail.parsed.html') || _.get(this, 'mail.parsed.textAsHtml') || _.get(this, 'mail.parsed.text');
        if(html) {
            let blob = new Blob([html], {type: 'text/html'});
            this.html = URL.createObjectURL(blob);
        } else {
            this.html = 'about:blank';
        }
    },

    onLoaded() {
        var iframe = document.getElementById('iframe');
        iframe.style.height = (iframe.contentWindow.document.body.scrollHeight + 30) + 'px';

        let url = iframe.src;
        if(url && url !== 'about:blank')
            URL.revokeObjectURL(url);
    },

    onScreenshotLoaded: function() {
      this.loaded = true;
    },

    onScreenshotFailed: function() {
      this.loadHtml();
    }
  },

  mounted() {
    this.reload();
  }
}
</script>
