<template>
  <b-row>
    <b-col cols="4">
      <img src="@/assets/traffic-lights-red.svg" v-if="scoreClass === 'phish'">
      <img src="@/assets/traffic-lights-yellow.svg" v-if="scoreClass === 'suspicious' || scoreClass === 'spam'">
      <img src="@/assets/traffic-lights-green.svg" v-if="scoreClass === 'normal'">
    </b-col>
    <b-col cols="8">
      <b-card-body>
        <b-card-title class="score" :class="scoreClass">Phishing Score: {{score}}</b-card-title>
        <b-card-sub-title class="mb-2" v-if="reliability && points">Zuverlässigkeit: {{reliability}}% ({{points}} Punkte)</b-card-sub-title>
        <b-card-text v-if="confirmed">
          Manuelle Entscheidung: {{displayDate(confirmed)}}
        </b-card-text>
        <b-card-text v-if="scoreClass === 'phish'">
          Achtung! Diese Nachricht ist mit hoher Wahrscheinlichkeit eine Pishing-E-Mail. Bitte öffnen Sie keine Links der Nachricht!
        </b-card-text>
        <b-card-text v-if="scoreClass === 'spam'">
          Bei dieser Nachricht handelt es sich vermutlich um Spam.
        </b-card-text>
        <b-card-text v-if="scoreClass === 'suspicious'">
          Die automatische Analyse der E-Mail hat kein eindeutiges Ergebis ermittelt.
        </b-card-text>
        <b-card-text v-if="scoreClass === 'normal'">
          Alles in Ordnung! Diese Nachricht sieht vertrauenswürdig aus.
        </b-card-text>
      </b-card-body>
    </b-col>
  </b-row>
</template>

<style scoped lang="scss">
.score {
  &.phish, &.error {
      color: red !important;
  }
  &.normal {
      color: green !important;
  }
  &.spam, &.suspicious {
      color: orange !important;
  }
}
</style>

<script>
import _ from 'lodash';
import ReportAdapter from '../ReportAdapter.js';

export default {
  name: 'ReportTrafficLights',
  props: ['report'],

  computed: {
    score() {
      return this.report ? ReportAdapter.score(this.report) : null;
    },

    points() {
      return this.report ? ReportAdapter.points(this.report) : null;
    },

    reliability() {
      return this.report ? ReportAdapter.reliability(this.report) : null;
    },

    scoreClass() {
      if(_.get(this.report, 'result.phish') === true)
        return 'phish';
      else if(_.get(this.report, 'result.spam') === true)
        return 'spam';
      else if(_.get(this.report, 'result.error') === true)
        return 'error';
      else if(_.get(this.report, 'result') && !('spam' in this.report.result) && !('phish' in this.report.result))
        return 'suspicious';
      else
          return 'normal';
    },

    confirmed() {
      return _.get(this.report, 'confirmed');
    }
  },

  watch: {
    report() {
    }
  },

  methods: {
    displayDate(val) {
        return new Date(val).toLocaleString();
    }
  }
}
</script>