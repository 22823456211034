import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import About from '../views/About.vue';
import Info from '../views/Info.vue';
import Tenants from '../views/Tenants.vue';
import CreateTenant from '../views/CreateTenant.vue';
import EditTenant from '../views/EditTenant.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'MailDefence: Home',
    component: Home
  },
  {
    path: '/about',
    name: 'Über MailDefence',
    component: About
  },
  {
    path: '/info',
    name: 'Information',
    component: Info
  },
  {
    path: '/tenants',
    name: 'Mandanten',
    component: Tenants
  },
  {
    path: '/tenants/create',
    name: 'Neuer Mandant',
    component: CreateTenant
  },
  {
    path: '/tenants/edit/:section/:id/:item?',
    name: 'Mandant bearbeiten',
    component: EditTenant
  },
  {
    path: '/report/:tenant/:id/:section?',
    name: 'Auswertung',
    component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue')
  },
  {
    path: '/confirm/:tenant/:id/:section?',
    name: 'Bestätigung',
    component: () => import(/* webpackChunkName: "confirmation" */ '../views/Confirmation.vue')
  },
  {
    path: '/guard/:tenant/:mail/:id',
    name: 'Link Guard',
    component: () => import(/* webpackChunkName: "report" */ '../views/Link.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});

export default router;
