<template>
  <div class="tenant">
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="administrator" label="Administratoren:"
        description="Gruppennamen oder Ids der Administratoren dieses Mandanten.">

        <b-input-group class="group" v-for="x in permissions.administrator" :key="permissions.administrator.indexOf(x)">
            <b-form-input v-model="permissions.administrator[permissions.administrator.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
            <b-input-group-append v-if="isAdmin">
                <b-button title="Entfernen" @click="permissions.administrator.splice(permissions.administrator.indexOf(x), 1)">
                    <b-icon icon="trash"/>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <b-button size="sm" class="mr-1 add" @click="permissions.administrator.push({isnew: true, value: ''})" v-if="isAdmin">
            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
        </b-button>
      </b-form-group>

      <b-form-group id="info" label="Informationszugriff:"
        description="Gruppennamen oder Ids der Gruppen dieses Mandanten, die lesenden Zugriff auf Statistiken und Einstellungen haben.">

        <b-input-group class="group" v-for="x in permissions.info" :key="permissions.info.indexOf(x)">
            <b-form-input v-model="permissions.info[permissions.info.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
            <b-input-group-append v-if="isAdmin">
                <b-button title="Entfernen" @click="permissions.info.splice(permissions.info.indexOf(x), 1)">
                    <b-icon icon="trash"/>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <b-button size="sm" class="mr-1 add" @click="permissions.info.push({isnew: true, value: ''})" v-if="isAdmin">
            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
        </b-button>
      </b-form-group>

      <b-form-group id="confirmation" label="Bestätigungen:"
        description="Gruppennamen oder Ids der Gruppen dieses Mandanten, die Bestätigungen sehen und ändern können.">

        <b-input-group class="group" v-for="x in permissions.confirmation" :key="permissions.confirmation.indexOf(x)">
            <b-form-input v-model="permissions.confirmation[permissions.confirmation.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
            <b-input-group-append v-if="isAdmin">
                <b-button title="Entfernen" @click="permissions.confirmation.splice(permissions.confirmation.indexOf(x), 1)">
                    <b-icon icon="trash"/>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <b-button size="sm" class="mr-1 add" @click="permissions.confirmation.push({isnew: true, value: ''})" v-if="isAdmin">
            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
        </b-button>
      </b-form-group>

      <b-form-group id="mailbox" label="Mailbox-Zugriff:"
        description="Gruppennamen oder Ids der Gruppen dieses Mandanten, die Zugriff auf die E-Mails in der Mailbox haben.">

        <b-input-group class="group" v-for="x in permissions.mailbox" :key="permissions.mailbox.indexOf(x)">
            <b-form-input v-model="permissions.mailbox[permissions.mailbox.indexOf(x)].value" :disabled="!x.isnew" placeholder="Gruppenname oder -Id"></b-form-input>
            <b-input-group-append v-if="isAdmin">
                <b-button title="Entfernen" @click="permissions.mailbox.splice(permissions.mailbox.indexOf(x), 1)">
                    <b-icon icon="trash"/>
                </b-button>
            </b-input-group-append>
        </b-input-group>
        <b-button size="sm" class="mr-1 add" @click="permissions.mailbox.push({isnew: true, value: ''})" v-if="isAdmin">
            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Hinzufügen</span>
        </b-button>
      </b-form-group>

      <div v-if="isAdmin">
        <b-button type="submit" variant="primary" :disabled="isSubmitDisabled">Speichern</b-button>
        <b-button type="reset" variant="default" :disabled="isResetDisabled">Abbrechen</b-button>
      </div>
    </b-form>
  </div>
</template>

<style scoped>
  button {
    margin-right: 10px;
  }
  button.add {
    margin-top: 10px;
  }
  .group {
    margin-bottom: 5px;
  }
</style>

<script>
import _ from 'lodash';
import config from '../config';

export default {
  name: 'CreateTenant',
  props: ['id'],
  data() {
    return {
      permissions: {
        administrator: [],
        info: [],
        confirmation: [],
        mailbox: [],
      },
      privileges: {},
      changed: false,
      loaded: false,
      show: true
    }
  },

  computed: {
    isSubmitDisabled() {
      return !this.changed;
    },

    isResetDisabled() {
      return !this.changed && !!this.id;
    },
    isAdmin() {
      return !!_.get(this.privileges, 'administrator')
    }
  },

  watch: {
    permissions: {
      handler() {
        this.changed = !!this.loaded;
        this.$emit('changed', this.permissions);
      },
      deep: true
    }
  },

  methods: {
    reload() {
      this.loaded = false;
      this.changed = false;

      if(this.id) {
        fetch(config.apiUrl('/tenant/' + this.id), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
          then(response => config.checkResponse(this, response)).
          then(data => {
            this.permissions = {
                administrator: _.get(data, 'permissions.administrator', []).map(x => { return {value: x}; }),
                info: _.get(data, 'permissions.info', []).map(x => { return {value: x}; }),
                confirmation: _.get(data, 'permissions.confirmation', []).map(x => { return {value: x}; }),
                mailbox: _.get(data, 'permissions.mailbox', []).map(x => { return {value: x}; })
            };
            this.privileges = data.privileges || {};
            this.$nextTick(() => (this.loaded = true));
          });
      } else {
        this.loaded = true;
      }
    },

    async onSubmit(evt) {
      evt.preventDefault()

      let url = config.apiUrl('/tenant') + '/' + this.id;
      let data = {
        permissions: {
            administrator: this.permissions.administrator.map(x => x.value),
            info: this.permissions.info.map(x => x.value),
            confirmation: this.permissions.confirmation.map(x => x.value),
            mailbox: this.permissions.mailbox.map(x => x.value)
        }
      };
      fetch(url, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => config.checkResponse(this, response)).then(() => {
        this.reload();
      });
    },

    onReset(evt) {
      evt.preventDefault();

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true;
      });

      if(this.id)
        this.reload();
      else
        this.$router.push('/tenants');
    }
  },

  mounted() {
    this.reload();
  }
}
</script>
