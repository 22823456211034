<template>
  <div class="page create-tenant">
    <h1>Neuer Mandant</h1>
    <CreateTenant/>
  </div>
</template>

<script>
// @ is an alias to /src
import CreateTenant from '@/components/CreateTenant.vue';

export default {
  props: ['profile'],
  components: {
    CreateTenant
  }
}
</script>