<template>
  <div class="home">
    <div class="stats" v-if="authenticated">
      <Stats />
    </div>
    <div v-else>
      <b-jumbotron>
        <div class="login" :class="{shown: loaded}">
          <img src="@/assets/icon-white.png" class="logo" fluid alt="Logo">
          <div class="title">MailDefence</div>
          <div class="text">Ihr zuverlässiger Schutz<br/>gegen E-Mail-Phishing.</div>
          <div class="action">
            <a :href="login" class="btn btn-outline-primary btn-lg">Zur Anmeldung</a>
          </div>
        </div>
      </b-jumbotron>
    </div>
  </div>
</template>

<style scoped lang="scss">
.home {
  text-align: center;
}

.login {
  opacity: 0;

  -webkit-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: -webkit-opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: ease-in;
  transition-timing-function: ease-in;

  width: 380px;
  height: 355px;
  padding: 30px 30px;
  background-image: url(../assets/background-color.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 16px;

  color: white;

  &.shown {
    opacity: 0.9;
  }
}

.action {
  margin-top: 30px;
}

.btn, .btn:hover, .btn:focus, .btn:active {
  color: white;
  border-color: white;
  border-radius: 16px;
}

.logo {
  margin-bottom: 30px;
  width: 70px;
}

.title {
  font-size: 36px;
  font-weight: 700;
}

.stats {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
.stats:after {
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 56px);
    content: " ";
    position: absolute;
    background: rgba(255,255,255,.75);
}
*/

.jumbotron, .stats {
  background-image: url(../assets/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0;
  height: calc(100vh - 56px);  /* height of navbar: 56px */

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<script>
import config from '../config';
import Stats from '@/components/Stats.vue';

export default {
  props: ['profile', 'loaded'],

  computed: {
    login() {
      return config.apiUrl('/login');
    },

    authenticated() {
      return !!this.profile;
    }
  },

  mounted() {
    // prevent scrollbar
    document.body.style = 'overflow-y: hidden';
  },
  
  destroyed() {
    // prevent scrollbar
    document.body.style = null;
  },

  components: {
    Stats
  }
}
</script>