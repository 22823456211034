<template>
    <div class="tenants">
        <b-button size="sm" class="mr-1 create" to="/tenants/create" v-if="privileges.administrator">
            <b-icon icon="person-plus" /> <span class="d-none d-sm-inline">Neuer Mandant</span>
        </b-button>
        <b-table ref="tenants" striped hover primary-key="id" :items="tenants" :fields="fields" @row-clicked="details">
            <template #cell(logo)="row">
                <img class="logo" v-if="row.item.logo" :src="row.item.logo">
                <div v-else class="logo">
                    <b-icon icon="slash-circle" scale="3" />
                </div>
            </template>
            <template #cell(actions)="row">
                <div class="actions">
                    <b-button size="sm" @click="remove(row.item)" class="mr-1" v-if="row.item.privileges.administrator">
                        <b-icon icon="trash" /> <span class="d-none d-sm-inline">Löschen</span>
                    </b-button>
                </div>
            </template>
        </b-table>
    </div>
</template>

<style>
    .table td {
        vertical-align: middle !important;
    }
    .tenants .table > tbody > tr {
        height: 125px;
    }
</style>
<style scoped>
    .logo {
        max-width: 100px;
        max-height: 100px;
        text-align: center;
        color: darkgray;
    }
    .create {
        float: right;
        margin-bottom: 20px;
    }
    .actions {
        text-align: right;
    }
</style>

<script>
import config from '../config';

export default {
  name: 'Tenants',

  data() {
    return {
        fields: [{
            key: 'logo',
            label: 'Logo'
        }, {
            key: 'name',
            label: 'Name',
            sortable: true
        }, {
            key: 'created',
            label: 'Erstellt',
            sortable: true,
            formatter: 'displayDate'
        }, {
            key: 'actions', label: ''
        }],
        tenants: [],
        privileges: {}
    };
  },

  methods: {
    reload() {
      fetch(config.apiUrl('/tenant'), {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
        then(response => config.checkResponse(this, response)).
        then(data => {
            this.tenants = data.items;
            this.privileges = data.privileges || {};
        });
    },

    displayDate(val) {
      return new Date(val).toLocaleString();
    },

    details(item) {
        this.$router.push('/tenants/edit/stats/' + item.id);
    },

    remove(item) {
        this.$bvModal.msgBoxConfirm('Sind Sie sicher?').then(value => {
            if(value) {
                fetch(config.apiUrl('/tenant/' + item.id), {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => config.checkResponse(this, response)).then(this.reload);
            }
        }).catch(err => {
            console.log(err);
        });
    }
  },

  mounted() {
    this.reload();
  }
}
</script>
