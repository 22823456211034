<template>
    <div class="training">
        <b-card title="Deep Learning-Klassifizierung">
          <p>Sie verfügen über kein eigenes KI-Modell und nutzen das Standardmodell.</p>
          <p>Erst wenn genügend E-Mails bestätigt wurden, können Sie ein eigenes KI-Modell trainieren.</p>

          <b-form>
              <b-form-group
                  id="input-group-use-default"
                  label="KI-Modellauswahl:"
                  label-for="use-default"
                  description="Sie können das generische Standard-KI-Modell zur Analyse der E-Mails nutzen. Dieses ist jedoch nicht für ihre E-Mails optimiert und liefert deshalb weniger gute Vorhersagen.">
                  <b-form-checkbox
                      id="secure"
                      v-model="model['use-default']"
                      name="use-default"
                      @change="onChangeUseDefault"
                      value="true">
                      Generisches Standard-KI-Modell verwenden
                  </b-form-checkbox>
              </b-form-group>
          </b-form>
        </b-card>

        <b-card title="Few-Shot-Klassifizierung">
          <p>Für die Few-Shot-Klassifizierung geben Sie Sätze oder kurze Abschnitte vor, die von einem Deep Learning-Modell als Normal oder Phishing erkannt werden sollen.
            Das Modell erkennt daraufhin semantische Ähnlichkeiten und klassifiziert künftige Nachrichten anhand dieser Vorgaben.</p>

          <p>Bitte erfassen Sie für normale E-Mails und für Phishing-E-Mails jeweils mindestens 5 Sätze/Abschnitte. Trennen Sie die Sätze mit einem Zeilenumbruch.</p>

          <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                  id="input-group-1"
                  label="Sätze normaler E-Mails:"
                  label-for="normal"
                  description="Erfassen Sie hier gängige Inhalte normaler bzw. erwünschter Nachrichten">
                  <b-form-textarea
                      id="normal"
                      v-model="fewshot.normal"
                      rows="5">
                  </b-form-textarea>
              </b-form-group>

              <b-form-group
                  id="input-group-2"
                  label="Sätze von Phishing-E-Mails:"
                  label-for="phish"
                  description="Erfassen Sie hier Sätze oder kurze Inhalte von typischen Phishing-Nachrichten">
                  <b-form-textarea
                      id="phish"
                      v-model="fewshot.phish"
                      rows="5">
                  </b-form-textarea>
              </b-form-group>

              <div>
                <b-button type="submit" variant="primary" :disabled="isSubmitDisabled">Speichern</b-button>
                <b-button type="reset" variant="default" :disabled="isResetDisabled">Abbrechen</b-button>
              </div>
          </b-form>
        </b-card>

        <b-card title="Aktueller Bestätigungsstatus">
          <p><b-link :to="'/tenants/edit/confirmations/' + (this.$route.params.tenant || this.$route.params.id)"><strong>Offene Bestätigungen:</strong> {{waiting}}</b-link></p>
          <Stats :id="$route.params.id" :report="$route.params.item" confirmed="true" />
        </b-card>
    </div>
</template>

<style scoped>
.card {
    margin-bottom: 20px;
}
</style>

<script>
import _ from 'lodash';
import config from '../config';
import Stats from '@/components/Stats.vue';

export default {
  name: 'Training',
  props: ['id'],

  data() {
    return {
        waiting: 0,
        confirmed: {},
        model: {
          'use-default': false
        },
        fewshot: {
          normal: null,
          phish: null
        },
        changed: false
    }
  },

  computed: {
    isSubmitDisabled() {
      return !this.changed;
    },

    isResetDisabled() {
      return !this.changed;
    }
  },

  watch: {
    fewshot: {
      handler() {
        this.changed = true;
      },
      deep: true
    }
  },

  methods: {
    reload() {
      let url = config.apiUrl('/confirmation') + '?start=0&count=1&tenant=' + (this.$route.params.tenant || this.$route.params.id);
      return fetch(url, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          }
        }).
        then(response => config.checkResponse(this, response)).catch(() => {}).
        then(data => {
            this.waiting = _.get(data, 'total', 0);
        }).then(() => {
          let url = config.apiUrl('/nlp/fewshot/all') + '?tenant=' + (this.$route.params.tenant || this.$route.params.id);
          return fetch(url, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              }
            }).
            then(response => config.checkResponse(this, response)).catch(() => {}).
            then(data => {
                this.fewshot.normal = _.get(data, 'normal', []).join('\n'),
                this.fewshot.phish = _.get(data, 'phish', []).join('\n')
            });
        }).then(() => {
          let url = config.apiUrl('/nlp/model/all') + '?tenant=' + (this.$route.params.tenant || this.$route.params.id);
          return fetch(url, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json'
              }
            }).
            then(response => config.checkResponse(this, response)).catch(() => {}).
            then(data => {
                this.model['use-default'] = !!_.get(data, 'use-default');
            });
        });
    },

    async onSubmit(evt) {
      evt.preventDefault()

      let url = config.apiUrl('/nlp/fewshot/all') + '?tenant=' + (this.$route.params.tenant || this.$route.params.id);
      let data = {
        normal: (this.fewshot.normal || '').split('\n').map(x => x.trim()).filter(x => !!x),
        phish: (this.fewshot.phish || '').split('\n').map(x => x.trim()).filter(x => !!x)
      };

      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => config.checkResponse(this, response)).then(() => {
        this.changed = false;
      });
    },

    onReset(evt) {
      evt.preventDefault();
      this.changed = false;
    },

    async onChangeUseDefault() {
      let url = config.apiUrl('/nlp/model/all') + '?tenant=' + (this.$route.params.tenant || this.$route.params.id);
      let data = {
        'use-default': !!this.model['use-default']
      };

      fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }).then(response => config.checkResponse(this, response));
    }
  },

  mounted() {
    this.reload();
  },

  components: {
    Stats
  }
}
</script>
