// polyfills for IE11 compatibility
require('es6-promise').polyfill();
require('intersection-observer');
import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';

import Vue from 'vue';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import './styles/styles.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
