<template>
    <div>
    <b-navbar toggleable="lg" type="dark" variant="info">
        <b-navbar-brand to="/">
          <img src="@/assets/logo-horizontal.png" class="logo" fluid alt="Logo">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
            <b-nav-item to="/tenants" v-if="authenticated">Mandanten</b-nav-item>
            <b-nav-item to="/about">Über MailDefence</b-nav-item>

            <b-nav-item-dropdown text="Sprache" right>
                <b-dropdown-item href="#">DE</b-dropdown-item>
            </b-nav-item-dropdown>

            <b-nav-item-dropdown right v-if="authenticated">
                <!-- Using 'button-content' slot -->
                <template #button-content>
                    Account
                </template>
                <b-dropdown-item href="#">Profil</b-dropdown-item>
                <b-dropdown-item :href="logout">Abmelden</b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
        </b-collapse>
    </b-navbar>
    </div>
</template>

<style lang="scss">
.navbar {
  background: transparent linear-gradient(266deg, #D2296A 0%, #00AEC7 100%) 0% 0% no-repeat padding-box;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.75) !important;

    &:hover, &:focus {
      color: rgba(255, 255, 255, 0.9) !important;
    }
}
</style>

<style scoped lang="scss">
.logo {
  width: 150px;
}
</style>

<script>
import config from '../config';

export default {
  name: 'Navbar',
  props: ['profile'],

  computed: {
    authenticated() {
      return !!this.profile;
    },

    logout() {
      return config.apiUrl('/logout');
    }
  }
}
</script>
