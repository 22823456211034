import _ from 'lodash';
import { Chart } from 'chart.js';

const chartColors = {
	red: 'rgb(255, 99, 132)',
	orange: 'rgb(255, 159, 64)',
	yellow: 'rgb(255, 205, 86)',
	green: 'rgb(75, 192, 192)',
	blue: 'rgb(54, 162, 235)',
    darkblue: 'rgb(2,9,211)',
	purple: 'rgb(153, 102, 255)',
	grey: 'rgb(201, 203, 207)'
};

const t = {
    smtpinfo: {
        title: 'Transport',
        color: chartColors.blue
    },
    smtptrace: {
        title: 'Sendeverfolgung',
        color: chartColors.grey
    },
    content: {
        title: 'Inhaltsanalyse',
        color: chartColors.green
    },
    similarity: {
        title: 'Inhaltsähnlichkeit',
        color: chartColors.orange
    },
    contentsimilarity: {
        title: 'Inhaltsähnlichkeit',
        color: chartColors.orange
    },
    phishtank: {
        title: 'Linkprüfung',
        color: chartColors.purple
    },
    phishingurls: {
        title: 'Linkprüfung',
        color: chartColors.purple
    },
    urls: {
        title: 'Linkprüfung',
        color: chartColors.purple
    },
    attachments: {
        title: 'Anhänge',
        color: chartColors.yellow
    },
    senderinfo: {
        title: 'Absenderreputation',
        color: chartColors.yellow
    },
    classification: {
        title: 'Klassifikation (AI)',
        color: chartColors.darkblue
    }
};

export default {
    categories(report) {
        let res = report ? _.filter(report.checks.map((x) => x.type), (x) => x !== 'webpageinfo') : [];
        return _.sortBy(res, x => this.title(x));
    },

    title(category) {
        return t[category] ? t[category].title : category;
    },

    color(category) {
        let color = t[category] ? t[category].color : chartColors.red;
        return Chart.helpers.color(color).alpha(0.5).rgbString();
    },

    propval(report, prop, category) {
        let val;
        if(!category || category === 'result')
            val = report[prop] || _.get(report, 'result.' + prop);
        if(_.isNumber(val))
            return val;
        
        val = _.find(report.checks, (x) => {
            return x.type === category;
        });
        if(val)
            val = _.get(val, 'result.' + prop);
        return val;
    },

    score(report, category) {
        let score = this.propval(report, 'score', category);
        if(_.isNumber(score))
            score = Math.round(score*10)/10;
        return score;
    },

    points(report, category) {
        let points = this.propval(report, 'points', category);
        if(_.isNumber(points))
            points = Math.round(points*10)/10;
        return points;
    },

    reliability(report, category) {
        let reliability = this.propval(report, 'reliability', category);
        if(_.isNumber(reliability))
            reliability = Math.round(reliability*100);
        return reliability;
    },

    calcScore(checks) {
        let scoreResults = checks.filter(x => x.score > 0 && x.reliability > 0);
        
        // prevent high scores only based on sender info/reputation
        let scoreResultsForCheck = scoreResults.filter(x => x.category !== 'senderinfo' || _.get(x, 'info.blacklisted'));
        
        // if no check has a very high weighted score, take average score
        let score = scoreResultsForCheck.length > 0 ? _.max(scoreResultsForCheck.map((x) => x.score * x.reliability)) : null;
        if(score && scoreResults.length > 0 && score < 9)
            score = _.sumBy(scoreResults, (x) => x.score) / scoreResults.length;
        if(score)
            score = Math.round(score*10)/10;

        // if no check has a very high reliability, take average reliability
        let reliability = scoreResultsForCheck.length > 0 ? _.maxBy(scoreResultsForCheck, (x) => x.reliability).reliability : null;
        if(reliability && scoreResults.length > 0 && reliability < 0.9)
            reliability = _.sumBy(scoreResults, (x) => x.reliability) / scoreResults.length;
        if(reliability)
            reliability = Math.round(reliability*100)/100;

        return {
          score: score,
          reliability: reliability,
        };
    }
};