import _ from 'lodash';
import config from './config';

export default {

    addToCustomList(tenant, type, email) {
        return fetch(config.apiUrl('/customlist/' + tenant) + `?type=${type}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => config.checkResponse(this, response)).then((res) => {
            if(_.isEmpty(res.items)) {
                return fetch(config.apiUrl('/customlist'), {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: `Standard ${type}`,
                        type: type,
                        tenant: tenant,
                        enabled: true
                    })
                }).then(response => {
                    if(response.ok)
                        return response.headers.get('location');
                    else
                        throw new Error(response.statusText);
                });
            } else {
                return config.apiUrl(`/customlist/${tenant}/${_.first(res.items).id}`);
            }
        }).then(url => {
            let list = _.last(url.split('/'));
            return fetch(config.apiUrl('/customlist/entry'), {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    list: list,
                    tenant: tenant,
                    email: email
                })
            }).then(response => config.checkResponse(this, response));
        });
    }
};