<template>
    <div id="chartdiv"></div>
</template>

<style>
#chartdiv {
    width: 100%;
    height: 500px;
    z-index: 10;
}

</style>
<script>
import _ from 'lodash';
import config from '../config';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

let timer = null;
let chart = null;

export default {
    name: 'Stats',
    props: ['id', 'report', 'color', 'confirmed'],

    methods: {
        getCurrentData() {
            let url = config.apiUrl('/stats');
            let tenant = (this.$route.params.tenant || this.$route.params.id);
            if(tenant)
                url += '?tenant=' + tenant;
            if(this.confirmed === 'true')
                url += (url.indexOf('?') === -1 ? '?' : '&') + 'confirmed=true';
            
            return fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
                }).
                then(response => config.checkResponse(this, response)).
                then(data => {
                    return data;
                });
        },

        loop(chart, label) {
            if(!timer)
                return;

            this.getCurrentData(label).then(stats => {
                if(!timer)
                    return;
                
                if(_.isEmpty(stats.data))
                    return;
                
                // it-sa demo
                /*
                console.log(window.location.hostname);
                if(!(this.$route.params.tenant || this.$route.params.id) && ['maildefence.segulab.de', 'maildefence-demo.mr-daten.dev', '127.0.0.1'].indexOf(window.location.hostname) !== -1) {
                    let _stats = {
                        total: 286438,
                        data: [{
                            status: 2,
                            count: 123922
                        }, {
                            status: 512,
                            count: 70239
                        }, {
                            status: 1024,
                            count: 89232
                        }, {
                            status: 2048,
                            count: 3045
                        }]
                    };

                    stats.total += _stats.total;
                    stats.data = _stats.data.map(x => {
                        let res = stats.data.find(y => x.status === y.status);
                        return {
                            status: x.status,
                            count: x.count + (res ? res.count : 0)
                        };
                    });
                }
                */

                label.text = stats.total;

                stats.data = stats.data.map(x => {
                    return {
                        status: config.mailStatus[x.status],
                        count: x.count,
                        color: config.statusColor[x.status]
                    };
                });
                chart.data = stats.data;

                chart.invalidateRawData();
                if(timer)
                    timer = chart.setTimeout( this.loop.bind(this, chart, label), 5000 );
            });
        }
    },

    mounted() {
        am4core.useTheme(am4themes_animated);

        // Create chart instance
        const self = this;
        chart = am4core.create("chartdiv", am4charts.PieChart);
        return this.getCurrentData().then(stats => {
            if(!chart)
                return;
            
            stats.data = stats.data.map(x => {
                return {
                    status: config.mailStatus[x.status],
                    count: x.count,
                    color: config.statusColor[x.status]
                };
            });
            chart.data = stats.data;

            // Add label
            chart.innerRadius = 100;
            let label = chart.seriesContainer.createChild(am4core.Label);
            label.text = stats.total;
            label.horizontalCenter = "middle";
            label.verticalCenter = "middle";
            label.fontSize = 50;

            let _color = am4core.color(self.color || 'black');
            label.fill = _color;

            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.slices.template.propertyFields.fill = "color";
            pieSeries.labels.template.text = "{category}:\n{value.percent.formatNumber('#.0')}%"
            //pieSeries.labels.template.maxWidth = 150;
            //pieSeries.labels.template.wrap = true;
            pieSeries.labels.template.fill = _color;
            pieSeries.dataFields.value = "count";
            pieSeries.dataFields.category = "status";

            timer = chart.setTimeout( this.loop.bind(this, chart, label));
        });
    },

    destroyed() {
        if(timer)
            clearTimeout(timer);
        timer = null;

        if(chart)
            chart.dispose();
        chart = null;
    }
}
</script>